import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Constant } from 'config/constant';
import { getListOneTimePayment, getDetailOneTimePayment } from 'api/oneTimePayment';
import { getErrorMessage } from 'api';
import { logout } from './authSlice';

export type ListOneTimePaymentState = {
    listOtPayment: {
        error: boolean;
        loading: boolean;
        success: boolean;
        otps: OtPaymentData[];
        pagination: Pagination;
        status: number;
    };
 
    detailOtPayment: {
        success: boolean;
        message: string;
        oneTimePayment: DetailOneTimePayment;
        loading: boolean;
        error: boolean;
        status: number;
        delete_available: boolean;
    };

};

// one record of otp list
export type OtPaymentData = {
    uuid: string;
    elepay_codeUrl: string;
    charging_fee: number;
    end_reason?: string;
    // charger_type_icon_url?: string;
    // charger_type?: string;
    charging_time_start?: string | undefined;
    charging_time_end?: string | undefined;
    final_charging_time?: number  | undefined;
    final_charging_power?: number  | undefined;
    final_charging_fee?: number  | undefined;
    transcation_id: string;
    elepay_orderNo: string;
};

export type DetailOneTimePayment = {
    uuid: string;
    authStatus?: string;
    charger_uuid?: string;
    charging_fee?: number;
    charging_hour?: number;
    charging_power?: string;
    charging_status?: number | undefined;
    charger_status?: number | undefined;
    charging_time?: string;
    charging_time_start?: string | undefined;
    charging_time_end?: string | undefined;
    chgFullStatus?: string;
    chgStatus?: string;
    chgSvcStatus?: string;
    created_at?: string;
    create_pay_check?: number;
    device_code?: string;
    elepay_amount?: string;
    elepay_charge_id?: string;
    elepay_clientIp?: string;
    elepay_codeUrl?: string;
    elepay_createTime?: number;
    elepay_currency?: string;
    elepay_description?: string;
    elepay_expiryPeriod?: string;
    elepay_expiryTime?: number;
    elepay_id?: string;
    elepay_liveMode?: string;
    elepay_object?: string;
    elepay_orderNo?: string;
    elepay_paid?: string;
    elepay_paidTime?: string;
    elepay_paymentMethod?: string;
    elepay_status?: string;
    elepay_type?: string;
    elepay_userAgent?: string;
    end_reason?: string;
    end_reason_raw?: string;
    errStatus?: string;
    final_charging_fee?: number | undefined;
    final_charging_time?: number  | undefined;
    final_charging_power?: number  | undefined;
    transcation_id?: string;
    lineStatus?: string;
    machineStatus?: string;
    phone_number?: string;
    s3_getStatus_resCd?: string;
    facility_uuid?: string;
    facility_name?: string| undefined;
    facility_location?: string| undefined;
};

export type ListOneTimePaymentRequest = {
    page?: number;
    startdate?: string;
    enddate?: string ;
    charger_uuid?: string;
    charger_maker_id?: string;
    charger_type_id?: string;
    charger_model_id?: string;
    status?: number;
    charger_status?: string;
    version_name?: string;
    device_code?: string;
    capacity?: string;
    phone_number?: string;
    start_time?: string;
    end_time?: string;
}

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ListOtPaymentRequest = {
    charger_uuid?: string;
    page?: number;
};

export const fetchListOneTimePayment = createAsyncThunk('charger/ot/payment/history', async (params: ListOneTimePaymentRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getListOneTimePayment(params);
        const {
            data = [],
            total = 0,
            perPage = Constant.PAGE_SIZE,
            currentPage = Constant.DEFAULT_PAGE,
            lastPage = Constant.DEFAULT_PAGE,
            success,
        } = response.data;

        if (success) {
            dispatch(setListOtPayment({ otps: data, pagination: { total, perPage, currentPage, lastPage } }));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListOtPayment(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const getDetailOneTimePaymentData = createAsyncThunk(
    'one-time-payment/:id',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailOneTimePayment(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailOtPayment(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDetailOtPayment(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const oneTimePaymentSlice = createSlice({
    name: 'listOneTimePayment',
    initialState: {
        listOtPayment: {
            error: false,
            loading: false,
            success: false,
            status: Constant.DEFAULT_STATUS,
            otps: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            }
        },
        detailOtPayment: {
            success: false,
            message: '',
            oneTimePayment: {} as DetailOneTimePayment,
            loading: false,
            error: false,
            delete_available: false,
            status:  Constant.DEFAULT_STATUS,
        }
    } as ListOneTimePaymentState,
    reducers: {
        setListOtPayment: (state: ListOneTimePaymentState, { payload }) => {
            state.listOtPayment.otps = payload?.otps;
            state.listOtPayment.pagination = payload?.pagination;
            state.listOtPayment.status = payload?.response?.status;
        },

        resetDetailOtPayment: (state: ListOneTimePaymentState) => {
            state.detailOtPayment.status = Constant.DEFAULT_STATUS;
            state.detailOtPayment = {
                loading: false,
                success: false,
                error: false,
                message: '',
                status: 0,
                oneTimePayment: {} as DetailOneTimePayment,
                delete_available: false,
            };
        },
        setDetailOtPayment: (state: ListOneTimePaymentState, { payload }) => {
            state.detailOtPayment.oneTimePayment = payload?.data;
            state.detailOtPayment.success = payload?.success;
            state.detailOtPayment.message = payload?.message;
            state.detailOtPayment.status = payload?.response?.status;
            state.detailOtPayment.delete_available = payload?.delete_available;
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListOneTimePayment.pending, (state: ListOneTimePaymentState) => {
                state.listOtPayment.loading = true;
            })
            .addCase(fetchListOneTimePayment.rejected, (state: ListOneTimePaymentState) => {
                state.listOtPayment.loading = false;
                state.listOtPayment.success = false;
                state.listOtPayment.error = true;
            })
            .addCase(fetchListOneTimePayment.fulfilled, (state: ListOneTimePaymentState) => {
                state.listOtPayment.loading = false;
                state.listOtPayment.success = true;
                state.listOtPayment.error = false;
            })
            
            .addCase(getDetailOneTimePaymentData.pending, (state: ListOneTimePaymentState) => {
                state.detailOtPayment.loading = true;
            })
            .addCase(getDetailOneTimePaymentData.rejected, (state: ListOneTimePaymentState, { payload }) => {
                state.detailOtPayment.loading = false;
                state.detailOtPayment.success = false;
                state.detailOtPayment.error = true;
                state.detailOtPayment.message = payload as string;
            })
            .addCase(getDetailOneTimePaymentData.fulfilled, (state: ListOneTimePaymentState, action) => {
                state.detailOtPayment.loading = false;
                state.detailOtPayment.success = true;
                state.detailOtPayment.error = false;
            })

    },
});

export const listOtPaymentSelector = (state: RootState) => state.otPayment;
export const { 
    setListOtPayment, 
    setDetailOtPayment,
    resetDetailOtPayment
} = oneTimePaymentSlice.actions;