import { getAxiosClientWithToken, getAxiosClientWithJWTToken } from "./index";
import { UpdatePassword, CreateAccountData, ResetPassword, sendEmailPassword, DeleteAccountData } from 'redux/slice/accountSlice';
import { handleFormData } from 'lib/utils';

export const getListAdmin = (params:any) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`facility/list-filter`, { params});
};

export const createAdmin = (params: CreateAccountData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/facility/account/create`, params);
};

export const deleteAdmin = (params: DeleteAccountData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/admin/account/delete`, params);
};

export const detailAdmin = (id: string | number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/accounts/${id}/detail`);
};

export const updateAdmin = (params: { id?: number | string; data: { role_id: number } }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`/admin/accounts/${params.id}/update`, formData);
};

export const changePassword = (params: UpdatePassword) => {
    const restClient = getAxiosClientWithJWTToken();
    //const formData = handleFormData(params);
    return restClient.post(`/cognito-password-change`, params);
};

export const sendEmail = (params: sendEmailPassword) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/forgot-password`, formData);
};

export const forgotPassword = (params: ResetPassword) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/reset-password`, formData);
};
