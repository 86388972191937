import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerModelData} from 'types'

export const createChargerModel= (params: RegisterChargerModelData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_model/create`, params);
};

export const getListChargerModel = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger-model/list', { params });
};

export const deleteChargerModel = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/charger_model/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};