import { getAxiosClientWithJWTToken } from 'api';
import { handleFormData } from 'lib/utils';
import { ListNotificationRequestNew } from 'redux/slice/notificationSlice';
import { CreateNotificationData, NotificationData } from 'types';

export const createNotification = (params: CreateNotificationData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/notifications/create`, params);
};

export const getListNotification = (params: ListNotificationRequestNew) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/notifications/list-by-uuids-page'+params.requestString);
};

export const updateNotification = (params: NotificationData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/notifications/update`, params);
};

export const pushNotification = (params: {id?: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    const formData = handleFormData(params);
    return restClient.post(`/notification/${params.id}/push-now`, formData);
};

export const getDetailNotification = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/notifications/details?uuid=`+params.id);
};

export const deleteNotification = (params: { id?: number | string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/notifications?uuid=` + params.id + `&lang=` + params.lang);
};

