import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import { logout } from './authSlice';
import {
    RegisterChargerMakerData
} from 'types';
import {createChargerMaker, getListChargerMaker, UpdateChargerMaker, deleteChargerMaker } from 'api/chargerMaker';

export type chargerMakerData = {
    uuid: string,
    name: string
}
export type ListChargerMaker = {
    error: boolean;
    loading: boolean;
    success: boolean;
    chargerMaker: chargerMakerData[];
    status: string;
};

export type ChargerMakerState = {
    status: number;
    chargerMakerData: {
        uuid: string;
        name: string;
        en_name: string;
    };
    listChargerMaker: ListChargerMaker;
    detailsChargerMaker: chargerMakerData;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterChargerMakerData;
    };
    update: {
        success: boolean;
        error: boolean;
        messages: string | RegisterChargerMakerData;
        loading: boolean;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string | RegisterChargerMakerData;
        loading: boolean;
        dataDetail: chargerMakerData;
    };
}

export const createChargerMakerA = createAsyncThunk(
    'admin/charger-maker/store',
    async (params: RegisterChargerMakerData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createChargerMaker(params);
            const { data = {}, success } = response.data;
            if (success) {

                dispatch(setNewChargerMaker(data));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setNewChargerMaker(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateChargerMaker = createAsyncThunk(
    '/admin/charger-maker/update',
    async (params: { uuid: string, name: string, lang: string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await UpdateChargerMaker(params);
            const { success } = response.data;
            if (success) {
            dispatch(setUpdateChargerMaker({params}));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setUpdateChargerMaker(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const destoryChargerMaker = createAsyncThunk(
    '/admin/charger-maker/delete',
    async (params: { uuid: string, lang:string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteChargerMaker(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDeleteChargerMaker({params}));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDeleteChargerMaker(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const chargerMakerSlice = createSlice(
    {
        name: 'chargerMaker',
        initialState: {
            status: Constant.DEFAULT_STATUS,
            chargerMakerData: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            update: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            delete: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            listChargerMaker: {
                error: false,
                loading: false,
                success: false,
                notification: [],
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
            listSearchPlace: {
                error: false,
                loading: false,
                success: false,
                places: [],
            }
        } as unknown as ChargerMakerState,
        reducers:{
            resetRegisterState: (state: ChargerMakerState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewChargerMaker: (state, action) => {
                state.chargerMakerData = action?.payload ?? [];
                state.status = action?.payload?.response?.status;
                state.register.message = action?.payload?.response?.data?.message;
            },
            setListChargerMaker: (state: ChargerMakerState, { payload }) => {
                const { chargerMaker } = payload;
                state.listChargerMaker.chargerMaker = chargerMaker;
            },
            setDetailsChargerMaker:(state: ChargerMakerState, {payload}) => {
                state.detailsChargerMaker = {
                    name: payload.detailsChargerMaker.name,
                    uuid: payload.detailsChargerMaker.uuid
                }
            },
            setDeleteChargerMaker: (state: ChargerMakerState, { payload }) => {
                state.status = payload?.response?.status;
            },
            setUpdateChargerMaker: (state, action) => {
                state.chargerMakerData = action?.payload?.params ?? [];
                state.status = action?.payload?.response?.status;
                state.update.messages = action?.payload?.response?.data?.message;
            },
            resetUpdateState: (state: ChargerMakerState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetDeleteState: (state: ChargerMakerState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.delete = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetListChargerMakerState: (state: ChargerMakerState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.update = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(createChargerMakerA.pending, (state: ChargerMakerState) => {
                    state.register.loading = true;
                })
                .addCase(createChargerMakerA.rejected, (state: ChargerMakerState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = false;
                    state.register.error = true;
                    state.register.message = payload as string | RegisterChargerMakerData;
                })
                .addCase(createChargerMakerA.fulfilled, (state: ChargerMakerState) => {
                    state.register.loading = false;
                    state.register.success = true;
                    state.register.error = false;
                })
                .addCase(updateChargerMaker.pending, (state: ChargerMakerState) => {
                    state.update.loading = true;
                })
                .addCase(updateChargerMaker.rejected, (state: ChargerMakerState) => {
                    state.update.loading = false;
                    state.update.success = false;
                    state.update.error = true;
                })
                .addCase(updateChargerMaker.fulfilled, (state: ChargerMakerState) => {
                    state.update.loading = false;
                    state.update.success = true;
                    state.update.error = false;
                })
                .addCase(fetchListChargerMaker.pending, (state: ChargerMakerState) => {
                    state.listChargerMaker.loading = true;
                })
                .addCase(fetchListChargerMaker.rejected, (state: ChargerMakerState) => {
                    state.listChargerMaker.loading = false;
                    state.listChargerMaker.success = false;
                    state.listChargerMaker.error = true;
                })
                .addCase(fetchListChargerMaker.fulfilled, (state: ChargerMakerState) => {
                    state.listChargerMaker.loading = false;
                    state.listChargerMaker.success = true;
                    state.listChargerMaker.error = false;
                })
                .addCase(destoryChargerMaker.pending, (state: ChargerMakerState) => {
                    state.delete.loading = true;
                    state.delete.success = false;
                    state.delete.error = false;
                })
                .addCase(destoryChargerMaker.rejected, (state: ChargerMakerState, {payload}) => {
                    state.delete.loading = false;
                    state.delete.success = false;
                    state.delete.error = true;
                    state.delete.messages = payload as string;
                })
                .addCase(destoryChargerMaker.fulfilled, (state: ChargerMakerState) => {
                    state.delete.loading = false;
                    state.delete.success = true;
                    state.delete.error = false;
                })
        },
    }
);

export const fetchListChargerMaker = createAsyncThunk(
    'admin/charger-maker',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListChargerMaker(params);
            const {
                data = [],
                success,
            } = response.data;
            if (success) {
                let m = 0;
                if(data.length>0) {
                    for (const elem of data) {
                        m+=1;
                        elem['no'] = m;
                    }
                }
                dispatch(setListChargerMaker({ chargerMaker: data }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListChargerMaker(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const {
    setListChargerMaker,
    resetRegisterState,
    setNewChargerMaker,
    setDeleteChargerMaker,
    resetUpdateState,
    setUpdateChargerMaker,
    resetDeleteState,
    resetListChargerMakerState,
    setDetailsChargerMaker
} = chargerMakerSlice.actions
export const chargerMakerSelector = (state: RootState) => state.chargerMaker;
