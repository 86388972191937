import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import { logout } from './authSlice';
import {
    RegisterWhiteUserData
} from 'types';
import {createWhiteListUser, getListWhiteUser, deleteWhiteListUser} from 'api/whiteListUser';

export type WhiteListUserDeleteData = {
    phone_number: string,
    lang: string
}

export type WhiteListUserData = {
    phone_number: string,
    user_name: string,
    created_at: string,
    company_name:string
}

export type ListWhiteUser = {
    error: boolean;
    loading: boolean;
    success: boolean;
    whiteList: WhiteListUserData[];
    status: string;
};

export type WhiteListUserState = {
    status : number;
    whiteListUserData : WhiteListUserData[];
    listWhiteListUser : ListWhiteUser;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
}

export const createWhiteUser= createAsyncThunk(
    'admin/facility-type/store',
    async (params: RegisterWhiteUserData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createWhiteListUser(params);
            const { data = {}, success } = response.data;
            if (success) {

                dispatch(setNewWhitelistUser(data));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setNewWhitelistUser(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);


export const destoryWhiteListUser = createAsyncThunk(
    '/admin/facility-type/delete',
    async (params: WhiteListUserDeleteData, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteWhiteListUser(params);
            const { success } = response.data;
            if (success) {
            dispatch(setDeleteWhiteUser({params}));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDeleteWhiteUser(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);


export const whiteListUserSlice = createSlice(
    {
        name: 'whiteListUser',
        initialState: {
            status: Constant.DEFAULT_STATUS,
            RegisterWhiteUserData: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            delete: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            listWhiteListUser: {
                error: false,
                loading: false,
                success: false,
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
        } as unknown as WhiteListUserState,
        reducers:{
            resetRegisterState: (state: WhiteListUserState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewWhitelistUser: (state, action) => {
                state.whiteListUserData = action?.payload ?? [];
                state.status = action?.payload?.response?.status;
                state.register.message = action?.payload?.response?.data?.message;
                console.log("page2", action?.payload?.response);
            },
            setListWhiteListUser: (state: WhiteListUserState, { payload }) => {
                state.listWhiteListUser.whiteList = payload ?? [];
            },
            
            setDeleteWhiteUser: (state: WhiteListUserState, { payload }) => {
                state.status = payload?.response?.status;
                state.delete.messages = payload?.response?.data?.message;
            },
            resetListWhiteUserState: (state: WhiteListUserState) => {
                state.status = Constant.DEFAULT_STATUS;
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(createWhiteUser.pending, (state: WhiteListUserState) => {
                    state.register.loading = true;
                })
                .addCase(createWhiteUser.rejected, (state: WhiteListUserState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = false;
                    state.register.error = true;
                    state.register.message = payload as string;
                })
                .addCase(createWhiteUser.fulfilled, (state: WhiteListUserState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = true;
                    state.register.error = false;
                })
                .addCase(fetchWhiteListUser.pending, (state: WhiteListUserState) => {
                    state.listWhiteListUser.loading = true;
                })
                .addCase(fetchWhiteListUser.rejected, (state: WhiteListUserState) => {
                    state.listWhiteListUser.loading = false;
                    state.listWhiteListUser.success = false;
                    state.listWhiteListUser.error = true;
                })
                .addCase(fetchWhiteListUser.fulfilled, (state: WhiteListUserState) => {
                    state.listWhiteListUser.loading = false;
                    state.listWhiteListUser.success = true;
                    state.listWhiteListUser.error = false;
                })
                .addCase(destoryWhiteListUser.pending, (state: WhiteListUserState) => {
                    state.delete.loading = true;
                    state.delete.success = false;
                    state.delete.error = false;
                })
                .addCase(destoryWhiteListUser.rejected, (state: WhiteListUserState, {payload}) => {
                    state.delete.loading = false;
                    state.delete.success = false;
                    state.delete.error = true;
                    state.delete.messages = payload as string;
                })
                .addCase(destoryWhiteListUser.fulfilled, (state: WhiteListUserState) => {
                    state.delete.loading = false;
                    state.delete.success = true;
                    state.delete.error = false;
                })
        },
    }
);

export const fetchWhiteListUser = createAsyncThunk(
    'admin/facility-type',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListWhiteUser(params);
            const {
                data = [],
                success,
            } = response.data;


            if (success) {
                let m = 0;
                if(data.length>0) {
                    for (const elem of data) {
                        m+=1;
                        elem['no'] = m;
                    }
                }

               // dispatch(setListFacilityType({ FacilityType: data }));
                dispatch(setListWhiteListUser(data));

                return true;
            }
        } catch (error: any) {
            dispatch(setListWhiteListUser(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const {
    setListWhiteListUser,
    resetRegisterState,
    setNewWhitelistUser,
    setDeleteWhiteUser,
    resetListWhiteUserState
} = whiteListUserSlice.actions
export const whiteListUserSelector = (state: RootState) => state.whiteListUser;

