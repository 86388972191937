import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { getCategory, getCategoryList, updateCategory } from 'api/category';
import { RootState } from 'redux/store';
import { Constant } from 'config/constant';
import { logout } from './authSlice';

export type RegisterCategoryValidate = {
    name: string | string[];
}

export type CategoryData = {
    uuid: string;
    color: string;
    name: string;
    lang: string;
};

export type ListCategory = {
    loading: boolean;
    success: boolean;
    error: boolean;
    categories: CategoryData[];
};

export type CategoryState = {
    status: number;
    categoryList: ListCategory;
    editCategory: {
        success: boolean;
        category: CategoryData;
        loading: boolean;
        error: boolean;
    };
    updateCategory: {
        success: boolean;
        error: boolean;
        messages: string | RegisterCategoryValidate;
        loading: boolean;
    };
};

export const fetchListCategory = createAsyncThunk('category', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getCategoryList();
        const { data = [], success } = response.data;
        if (success) {
            dispatch(setListCategory({ categories: data }));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListCategory(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return rejectWithValue(false);
});

export const editCategory = createAsyncThunk(
    'category/:id',
    async (params: { uuid : string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCategory(params);
            const { data, success } = response.data;
            if (success) {
                dispatch(setEditCategory({ categories: data }));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setEditCategory(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const updateItemCategory = createAsyncThunk(
    'category/:id/update',
    async (params: CategoryData, { dispatch, rejectWithValue }) => {
        try {
            let response = await updateCategory(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setUpdateCategory(data));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setUpdateCategory(error));
            return rejectWithValue(error);
        }
        return rejectWithValue(false);
    }
);

export const categorySlice = createSlice({
    name: 'categoryList',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        categoryList: {
            error: false,
            loading: false,
            success: false,
            categories: [],
        },
        editCategory: {
            success: false,
            loading: false,
            error: false,
            category: {} as CategoryData,
        },
        updateCategory: {
            success: false,
            error: false,
            loading: false,
            messages: '',
        },
    } as CategoryState,
    reducers: {
        setStatusCategory: (state: CategoryState) => {
            state.status = Constant.DEFAULT_STATUS;
        },
        setListCategory: (state: CategoryState, { payload }) => {
            const { categories } = payload;
            state.categoryList.categories = categories;
            state.status = payload?.response?.status;
        },
        setEditCategory: (state: CategoryState, { payload }) => {
            state.editCategory.category = payload?.categories;
        },
        setUpdateCategory: (state, action) => {
            state.status = action?.payload?.response?.status;

            if(action?.payload?.response?.data?.message){
                state.updateCategory.messages = action?.payload?.response?.data?.message
            }else{
                state.updateCategory.messages = "";
            }

        },
        resetUpdateCategory: (state) => {
            state.updateCategory = {
                success: false,
                error: false,
                messages: '' || {} as RegisterCategoryValidate,
                loading: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListCategory.pending, (state: CategoryState) => {
                state.categoryList.loading = true;
            })
            .addCase(fetchListCategory.rejected, (state: CategoryState) => {
                state.categoryList.loading = false;
                state.categoryList.success = false;
                state.categoryList.error = true;
            })
            .addCase(fetchListCategory.fulfilled, (state: CategoryState) => {
                state.categoryList.loading = false;
                state.categoryList.success = true;
                state.categoryList.error = false;
            })
            .addCase(updateItemCategory.pending, (state: CategoryState) => {
                state.updateCategory.loading = true;
            })
            .addCase(updateItemCategory.rejected, (state: CategoryState , { payload }) => {
                state.updateCategory.loading = false;
                state.updateCategory.success = false;
                state.updateCategory.error = true;
            })
            .addCase(updateItemCategory.fulfilled, (state: CategoryState) => {
                state.updateCategory.loading = false;
                state.updateCategory.success = true;
                state.updateCategory.error = false;
            })
            .addCase(editCategory.pending, (state: CategoryState) => {
                state.editCategory.loading = true;
            })
            .addCase(editCategory.rejected, (state: CategoryState , { payload }) => {
                state.editCategory.loading = false;
                state.editCategory.success = false;
                state.editCategory.error = true;
            })
            .addCase(editCategory.fulfilled, (state: CategoryState) => {
                state.editCategory.loading = false;
                state.editCategory.success = true;
                state.editCategory.error = false;
            });
    },
});

export const CategorySelector = (state: RootState) => state.categories;
export const { setListCategory, setEditCategory, setUpdateCategory, resetUpdateCategory } = categorySlice.actions;
