import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Constant } from "config/constant";
import { RootState } from "redux/store";
import { logout } from "./authSlice";
import { getReport } from "api/report";

export type searchUUIDType = {
  uuid_type: string;
  report_type:string;
  uuid: string;
  charger_type_id: string,
  charger_protocol_id: string,
  charger_maker_id: string,
  device_code: string,
  serial_number: string,
  charger_short_code: string,
  version_name: string,
  charging_status: string,
  place_id: string,
  place_type_id: string,
  start_time: string,
  end_time: string
};

export type reportParams = {
  charger_uuid: string;
}

type Pagination = {
  total: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
};

export const fetchReport = createAsyncThunk(
  "/report",
  async (params: searchUUIDType, { dispatch }) => {
    try {
      const response = await getReport(params);
      const { 
            data = [],
            total = 0,
            perPage = Constant.PAGE_SIZE,
            currentPage = Constant.DEFAULT_PAGE,
            lastPage = Constant.DEFAULT_PAGE,
            success,
      } = response.data;
      if (success) {
        dispatch(setListReport({ report: data, pagination: { total, perPage, currentPage, lastPage }}));
        return true;
      } else {
        dispatch(resetListReport());
      }
    } catch (error: any ) {
      if (error?.response?.data?.message === "TOKEN_EXPIRED") {
        dispatch(logout());
      }
      dispatch(setListReport(error));
    }
    return false;
  }
);

export type ListReport = {
  error: boolean;
  loading: boolean;
  success: boolean;
  report: ReportData[];
  status: string;
  pagination: Pagination;
};

export type ReportData = {
  facility_uuid: string;
  facility_name: string;
  chargers:chargerData[]
};

export type chargerData = {
  charger_uuid: string;
  charger_ID: string;
}

export type reportState = {
  status: number;
  reportData: ReportData[];
  listReport: ListReport;
};

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    status: Constant.DEFAULT_STATUS,
    reportData: [],
    listReport: {
      error: false,
      loading: false,
      success: false,
      report: [],
      status: "",
      pagination: {
        total: 0,
        perPage: Constant.PAGE_SIZE,
        currentPage: Constant.DEFAULT_PAGE,
        lastPage: Constant.DEFAULT_PAGE,
      },
    },
  } as reportState,
  reducers: {
    setStatusReport: (state: reportState) => {
      state.status = Constant.DEFAULT_STATUS;
    },
    setListReport: (state, { payload }) => {
      const { report, pagination} = payload;
      state.listReport.report = report;
      state.listReport.pagination = pagination;
      state.status = payload?.response?.status;
    },
    resetListReport: (state: reportState) => {
      state.status = Constant.DEFAULT_STATUS;
      state.listReport = {
        error: false,
        loading: false,
        success: false,
        report: [],
        status: "",
        pagination: {
          total: 0,
          perPage: Constant.PAGE_SIZE,
          currentPage: Constant.DEFAULT_PAGE,
          lastPage: Constant.DEFAULT_PAGE,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReport.pending, (state: reportState) => {
        state.listReport.loading = true;
      })
      .addCase(fetchReport.rejected, (state: reportState) => {
        state.listReport.loading = false;
        state.listReport.success = false;
        state.listReport.error = true;
      })
      .addCase(fetchReport.fulfilled, (state: reportState) => {
        state.listReport.loading = false;
        state.listReport.success = true;
        state.listReport.error = false;
      });
  },
});

export const { setListReport, resetListReport, setStatusReport } = reportSlice.actions;

export const reportSelector = (state: RootState) => state.report;
