import { getAxiosClientWithToken, getAxiosClientWithJWTToken } from 'api';
import { userEnableDisableData } from '../redux/slice/detailUserSlice';
import { ListUserRequest } from 'redux/slice/listUserSlice';

export const getUser = (id: string | number) => {
    return getAxiosClientWithJWTToken().get(`mobile-user/details?uuid=` + id);
};

export const updateStatusUser = (action: userEnableDisableData) => {
    return getAxiosClientWithJWTToken().post(`mobile-user/enable-disable`, action);
};

export const deleteUser = (id: string | number) => {
    return getAxiosClientWithToken().delete(`admin/user/${id}`);
};

export const getListUser = (params: ListUserRequest) => {
    return getAxiosClientWithJWTToken().get(`mobile-user`, { params });
};

export const getUserStatus = () => {
    return getAxiosClientWithToken().get('admin/user/statuses');
};

export const getUserStatistic = () => {
    return getAxiosClientWithToken().get('admin/user/statistic');
};

export const getStatisticUsers = (params: any) => {
    return getAxiosClientWithJWTToken().get('users/statistics', { params });
};

export const getChartDataUsers = (params: any) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`users/data-chart`, { params });
};

export const getAllUserList = () => {
    return getAxiosClientWithJWTToken().get(`mobile-user/get-user-list`);
};

