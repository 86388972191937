import { getAxiosClientWithToken, getAxiosClientWithJWTToken } from "api";
import { MyPlaceData, TurnOnOff, StatisticData } from 'types';
import { ListPlaceRequest } from 'redux/slice/listPlaceSlice';

export const getListPlace = (params: ListPlaceRequest) => {
    return getAxiosClientWithJWTToken().get("facility/list", { params });
};

export const createPlace = (params: MyPlaceData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/facility/create`, params);
};

export const getPrefecture = (params: { zipcode?: number , lang?: string | undefined }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/get-city', { params });
};

export const getCitybyState = (params: { prefecture?: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/get-city', { params });
};

export const editPlace = (params:MyPlaceData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/facility/update`, params);
};

export const getDetailPlace = (params: { id?: Number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/facility/details?uuid=${params.id}`);
};

export const getPlaceType = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get("/facilitytypes");
};

export const getState = () => {
  const restClient = getAxiosClientWithJWTToken();
  return restClient.get("/get-prefecture");
};

export const getPlaceStatistic = (params: StatisticData) => { 
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get("summary/statistic-v2", {params});
};

export const getAmountOfElectricity = (params: { day_search?: string; latitude?: string; longitude?: string }, id?: Number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/place/${id}/power-usage`, { params });
};

export const getPinPlace = (params: { latitude?: string; longitude?: string }, id?: Number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/map/pin-place/${id}`, { params });
};

export const stopPlace = (params: TurnOnOff) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`facility/switching`, params);
};

export const openPlace = (params: TurnOnOff) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`facility/switching`, params);
};

export const pinPlace = (params: { latitude : number | string | undefined, longitude : number | string | undefined }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/map/pin-place',{ params });
};

export const imageUpload = (filename: string, file: string | ArrayBuffer | null) => {
  const restClient = getAxiosClientWithJWTToken();
  return restClient.post(`file-upload`, { filename: filename, file: file });
};

export const imageRemove = (params: { filepath: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`admin/image-remove`, params);
};

export const getFacilityUuidNames = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/facility/list-uuid-name`);
};

export const deleteFacility = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/facility/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};