import {getAxiosClientWithJWTToken} from 'api';
import { RegisterAuthCardIDData } from 'redux/slice/AuthCardIDSlice';

export const CreateAuthCardID= (params: RegisterAuthCardIDData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/auth-card-ID/create`, params);
};

export const getListAuthCardID = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/auth-card-ID/list', { params });
};

export const deleteAuthCardID = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/auth-card-ID/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};