import { getAxiosClientWithJWTToken } from 'api';
import {ListOneTimePaymentRequest } from 'redux/slice/oneTImePaymentSlice';

export const getListOneTimePayment = (params: ListOneTimePaymentRequest) => {
    return getAxiosClientWithJWTToken().get(`charger/ot/payment/history`, { params });
};

export const getDetailOneTimePayment = (params: { id: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`charger/ot/payment/details?uuid=`+`${params.id}`);
};
