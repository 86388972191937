import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import { logout } from './authSlice';
import { CreateAuthCardID, deleteAuthCardID, getListAuthCardID } from 'api/authCardID';

export type ListAuthCardID = {
    error: boolean;
    loading: boolean;
    success: boolean;
    authCardID: authCardIDdata[];
    status: string;
    pagination: Pagination;
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type RegisterAuthCardIDData = {
    card_id: string,
    card_type: string;
    facility_uuid: string;
    chargers_uuid: string[];
    specific_charger: boolean;
}

export type authCardIDdata = {
    uuid: string;
    card_id: string;
    card_type: string;
    facility_name: string;
    chargers_uuid: string[];
    specific_charger: boolean;
}

export type AuthCardIDState = {
    status: number;
    authCardIDdata: {
        uuid: string,
        card_id: string,
        card_type: string;
        facility_name: string;
        charger_uuid: string;
    };
    listAuthCardID: ListAuthCardID;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | RegisterAuthCardIDData;
    };
    delete: {
        success: boolean;
        error: boolean;
        message: string;
        loading: boolean;
    };
}

export const AuthCardIDSlice = createSlice(
    {
        name: 'authCardID',
        initialState:{
            status: Constant.DEFAULT_STATUS,
            authCardIDdata: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            delete: {
                success: false,
                error: false,
                message: '',
                loading: false,
            },
            listAuthCardID: {
                error: false,
                loading: false,
                success: false,
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
        } as unknown as AuthCardIDState,
        reducers:{
            resetRegisterState: (state: AuthCardIDState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewAuthCardID: (state, action) => {
                state.authCardIDdata = action?.payload ?? [];
                state.status = action?.payload?.response?.status;
                state.register.message = action?.payload?.message;
            },
            setListAuthCardID: (state: AuthCardIDState, { payload }) => {
                const { authCardID, pagination } = payload;
                state.listAuthCardID.authCardID = authCardID;
                state.listAuthCardID.pagination = pagination;
            },
            setDeleteAuthCardID: (state: AuthCardIDState, { payload }) => {
                state.status = payload?.response?.status;
                state.delete.message = payload?.response?.data?.message;
            },
            resetDeleteState: (state: AuthCardIDState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.delete = {
                    loading: false,
                    success: false,
                    error: false,
                    message: '',
                };
            },
        },
        extraReducers: (builder) => {
            builder
            .addCase(registerAuthCardID.pending, (state: AuthCardIDState) => {
                state.register.loading = true;
            })
            .addCase(registerAuthCardID.rejected, (state: AuthCardIDState, {payload}) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string;
            })
            .addCase(registerAuthCardID.fulfilled, (state: AuthCardIDState) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(fetchListAuthCardID.pending, (state: AuthCardIDState) => {
                state.listAuthCardID.loading = true;
            })
            .addCase(fetchListAuthCardID.rejected, (state: AuthCardIDState) => {
                state.listAuthCardID.loading = false;
                state.listAuthCardID.success = false;
                state.listAuthCardID.error = true;
                state.listAuthCardID.pagination = {
                    total: 0,
                    perPage: Constant.PAGE_SIZE,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                }
            })
            .addCase(fetchListAuthCardID.fulfilled, (state: AuthCardIDState) => {
                state.listAuthCardID.loading = false;
                state.listAuthCardID.success = true;
                state.listAuthCardID.error = false;
            })
            .addCase(destoryAuthCardID.pending, (state: AuthCardIDState) => {
                state.delete.loading = true;
            })
            .addCase(destoryAuthCardID.rejected, (state: AuthCardIDState, {payload}) => {
                state.delete.loading = false;
                state.delete.success = false;
                state.delete.error = true;
                state.delete.message = payload as string;
            })
            .addCase(destoryAuthCardID.fulfilled, (state: AuthCardIDState) => {
                state.delete.loading = false;
                state.delete.success = true;
                state.delete.error = false;
            })
        }
    }
)

export const registerAuthCardID = createAsyncThunk(
    'admin/auth-card-id/store',
    async (params: RegisterAuthCardIDData, { dispatch, rejectWithValue }) => {
        try {
            let response = await CreateAuthCardID(params);
            const { success } = response.data;
            if (success) {

                dispatch(setNewAuthCardID(response.data));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setNewAuthCardID(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchListAuthCardID = createAsyncThunk(
    'admin/list-auth-card-id',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListAuthCardID(params);
            const {
                data = [],
                total = 0,
                perPage = Constant.PAGE_SIZE,
                currentPage = Constant.DEFAULT_PAGE,
                lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListAuthCardID({ authCardID: data,pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setListAuthCardID(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const destoryAuthCardID = createAsyncThunk(
    '/admin/auth-card-id/delete',
    async (params: { uuid: string, lang:string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteAuthCardID(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDeleteAuthCardID({response}));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDeleteAuthCardID(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const{
    setListAuthCardID,
    setNewAuthCardID,
    setDeleteAuthCardID,
    resetRegisterState,
    resetDeleteState
} = AuthCardIDSlice.actions
export const AuthCardIDSelector = (state: RootState) => state.authCardID