import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { getListChargerHistory } from 'api/listChargerHistory';
import { Constant } from 'config/constant';
import { logout } from './authSlice';

export type ListChargerHistoryState = {
    error: boolean;
    loading: boolean;
    success: boolean;
    chargerHistory: ChargerHistoryData[];
    pagination: Pagination;
    status: number;
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ChargerHistoryData = {
    booking_id: number;
    charging_id: number;
    is_booking: number;
    place_id: number;
    place_code: string;
    place: string;
    user_id: number;
    user_first_name: string;
    user_last_name: string;
    user_name: string;
    charger_id: number;
    charger_code: string;
    charger_type_id: number;
    charger_type: string;
    charging_status_id: number;
    charging_status: number;
    charging_date_start: string;
    charging_time_start: string;
    charging_date_end: string;
    charging_time_end: string;
    charging_time: number;
    charging_amount: number;
    electricity_fee: number;
    charging_fee: number;
    charging_bill_status: string;
    charging_status_icon_url: string;
    charger_type_icon_url: string;
    electric_bill: string;
    charging_power: string;
    charging_bill: string;
    is_booking_cancel: number;
    final_operator: string;
    uuid: string;
    facility_uuid: string;
    place_uuid: string;
    final_charging_fee: number;
    final_charging_time: number;
    final_charging_time_min: number;
    final_charging_power: string;
    end_reason: string;
    order_id: string;
    paid_off: number;
    reservation_exceed_cancelled_by: string;
    charger_reserve_uuid: string;
};

export type ListChargerHistoryRequest = {
    page?: number;
    place_type_id?: number[];
    charger_type_id?: number[];
    start_time: string | null;
    prefecture_code: string | null;
    city_code?: string[];
    end_time: string | null;
};

export const fetchListChargerHistory = createAsyncThunk(
    'admin/charging-history',
    async (params: ListChargerHistoryRequest, { dispatch }) => {
        try {
            const response = await getListChargerHistory(params);
            const {
                data = [],
                total = 0,
                per_page: perPage = Constant.PAGE_SIZE,
                current_page: currentPage = Constant.DEFAULT_PAGE,
                last_page: lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;

            if (success) {
                dispatch(
                    setListChargerHistory({
                        chargerHistory: data,
                        pagination: { total, perPage, currentPage, lastPage },
                    })
                );
                return true;
            }
            return false;
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setListChargerHistory(error));
        }
        return false;
    }
);

export const listChargerHistorySlice = createSlice({
    name: 'listChargerHistory',
    initialState: {
        error: false,
        loading: false,
        success: false,
        chargerHistory: [],
        pagination: {
            total: 0,
            perPage: Constant.PAGE_SIZE,
            currentPage: Constant.DEFAULT_PAGE,
            lastPage: Constant.DEFAULT_PAGE,
        },
        status: Constant.DEFAULT_STATUS,
    } as ListChargerHistoryState,
    reducers: {
        setListChargerHistory: (state: ListChargerHistoryState, { payload }) => {
            const { chargerHistory, pagination } = payload;
            state.chargerHistory = chargerHistory;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListChargerHistory.pending, (state: ListChargerHistoryState) => {
                state.loading = true;
            })
            .addCase(fetchListChargerHistory.rejected, (state: ListChargerHistoryState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            })
            .addCase(fetchListChargerHistory.fulfilled, (state: ListChargerHistoryState) => {
                state.loading = false;
                state.success = true;
                state.error = false;
            });
    },
});

export const listChargerHistorySelector = (state: RootState) => state.listChargerHistory;
export const { setListChargerHistory } = listChargerHistorySlice.actions;
