import 'assets/styles/app.scss';

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from 'components/layouts/BaseLayout';
import ListPlace from 'pages/Place/List';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { fetchUserData, selectAuth } from './redux/slice/authSlice';
import Loader from './components/layouts/Loader';
import SuccessModal from 'components/layouts/SuccessModal';
import { useTranslation } from 'react-i18next';
const AccountSetting = lazy(() => import('pages/AccountSetting'));
const Login = lazy(() => import('pages/Login/index'));
const Chargers = lazy(() => import('pages/Charger'));
const ListCharger = lazy(() => import('pages/Charger/List'));
const AddCharger = lazy(() => import('pages/Charger/Create'));
const EditCharger = lazy(() => import('pages/Charger/Edit'));
const DetailChargers = lazy(() => import('pages/Charger/Detail'));
const ListUser = lazy(() => import('pages/User/ListUser'));
const DetailUser = lazy(() => import('pages/User/DetailUser'));
const Places = lazy(() => import('pages/Place'));
const AddPlace = lazy(() => import('pages/Place/Create'));
const DetailPlaces = lazy(() => import('pages/Place/Detail'));
const EditPlaces = lazy(() => import('pages/Place/Edit'));
const HistoryCharger = lazy(() => import('pages/HistoryCharger'));
const ListAllHistoryCharger = lazy(() => import('pages/HistoryCharger/ListAllHistory'));
const DetailHistoryCharger = lazy(() => import('pages/HistoryCharger/Detail'));
const DetailBookingCancel = lazy(() => import('pages/HistoryCharger/DetailBookingCancel'));
const OneTimePayment = lazy(() => import('pages/OneTimePayment'));
const ListOneTimePayment = lazy(() => import('pages/OneTimePayment/List'));
const DetailOneTimePayment = lazy(() => import('pages/OneTimePayment/Detail'));
const ListHistory = lazy(() => import('pages/HistoryCharger/ListHistory'));
const Forbidden = lazy(() => import('pages/Forbidden'));
const InternalServer = lazy(() => import('pages/InternalServer'));
const NotFound = lazy(() => import('pages/Error404'));
const BookingList = lazy(() => import('pages/Charger/BookingList'));
const CreateAccount = lazy(() => import('pages/AccountSetting/Create'));
const EditAccount = lazy(() => import('pages/AccountSetting/Edit'));
const Home = lazy(() => import('pages/Home/index'));
const Notification = lazy(() => import('pages/Notification'));
const NotificationList = lazy(() => import('pages/Notification/List'));
const AddNotification = lazy(() => import('pages/Notification/Create'));
const DetailNotification = lazy(() => import('pages/Notification/Detail'));
const EditNotification = lazy(() => import('pages/Notification/Edit'));
const Category = lazy(() => import('pages/Category'));
const CategoryList = lazy(() => import('pages/Category/List'));
const Map = lazy(() => import('pages/Map/index'));
const FacilityType = lazy(() => import('pages/FacilityType/Index'))
const FacilityTypeList = lazy(() => import('pages/FacilityType/List'))
const WhiteUser = lazy(() => import('pages/WhiteList/Index'));
const WhiteUserList = lazy(() => import('pages/WhiteList/List'));
const WhiteUserCreate = lazy(() => import('pages/WhiteList/Create'));
const ChargerMaker = lazy(() => import('pages/ChargerMaker/Index'))
const ChargerMakerList = lazy(() => import('pages/ChargerMaker/List'))
const AddChargerMaker = lazy(() => import('pages/ChargerMaker/Create'))
const AddFacilityType = lazy(() => import('pages/FacilityType/Create'));
const Coupon = lazy(() => import('pages/Coupon/index'));
const CouponList = lazy(() => import('pages/Coupon/List'));
const CreateCoupon = lazy(() => import('pages/Coupon/Create'));
const DetailCoupon = lazy(() => import('pages/Coupon/Details'));
const ChargerType = lazy(() => import('pages/ChargerType/Index'));
const ChargerTypeList = lazy(() => import('pages/ChargerType/List'));
const AddChargerType = lazy(() => import('pages/ChargerType/Create'));
const ChargerProtocol = lazy(() => import('pages/ChargerProtocol/Index'));
const ChargerProtocolList = lazy(() => import('pages/ChargerProtocol/List'));
const AddChargerProtocol = lazy(() => import('pages/ChargerProtocol/Create'));
const CouponHistoryList = lazy(() => import('pages/CouponHistory/List'));
const CouponHistoryDetail = lazy(() => import('pages/CouponHistory/Detail'));
const LogList = lazy(() => import('pages/Log/List'));
const DetailLog = lazy(() => import('pages/Log/Details'));
const Report = lazy(() => import('pages/Reports'));
const FacilityChargerReport = lazy(() => import('pages/Reports/FacilityChargerReport'))
const ChargerModel = lazy(() => import('pages/ChargerModel/Index'))
const ChargerModelList = lazy(() => import('pages/ChargerModel/List'))
const AddChargerModel = lazy(() => import('pages/ChargerModel/Create'))
const HoldBooking = lazy(() => import('pages/HoldBookingHistory'));
const MonthlyFees = lazy(() => import('pages/MonthlyFees'));
const ListHoldBooking = lazy(() => import('pages/HoldBookingHistory/List'));
const ListMonthlyFees = lazy(() => import('pages/MonthlyFees/List'));
const ScheduleBooking = lazy(() => import('pages/ScheduleBooking'))
const ListScheduleBoooking = lazy(() => import('pages/ScheduleBooking/List'));
const DetailScheduleBooking = lazy(() => import('pages/ScheduleBooking/Details'))
const AuthCardID = lazy(() => import('pages/AuthCardID'))
const AuthCardIDList = lazy(() => import('pages/AuthCardID/List'))
const AddAuthCardID = lazy(() => import('pages/AuthCardID/Create'))

const App = () => {
    const { token } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['home']);
    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [dispatch, token]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader force />}>
                <Routes>
                    <Route path='/' element={<BaseLayout />}>
                        <Route index element={<Home />} />
                        <Route path='/chargers' element={<Chargers />}>
                            <Route path='create' element={<AddCharger />} />
                            <Route path=':id/edit' element={<EditCharger />} />
                            <Route path='detail/:id' element={<DetailChargers />} />
                            <Route path='list-chargers/:id' element={<ListCharger />} />
                            <Route path=':charger_id/booking-list' element={<BookingList />} />
                            <Route path='chargers-history/:id' element={<ListHistory />} />
                        </Route>
                        <Route path='/places' element={<Places />}>
                            <Route index element={<ListPlace />} />
                            <Route path='create' element={<AddPlace />} />
                            <Route path='detail/:id' element={<DetailPlaces />} />
                            <Route path=':id/edit' element={<EditPlaces />} />
                        </Route>
                        <Route path='/auth-card-id' element={<AuthCardID />}>
                            <Route index element={<AuthCardIDList />} />
                            <Route path='create' element={<AddAuthCardID />} />
                        </Route>
                        <Route path='/white-list' element={<WhiteUser />}>
                            <Route index element={<WhiteUserList />} />
                            <Route path='create' element={<WhiteUserCreate />} />
                        </Route>
                        <Route path='/facility-type' element={<FacilityType />}>
                            <Route index element={<FacilityTypeList />} />
                            <Route path='create' element={<AddFacilityType />} />
                        </Route>
                        <Route path='/charger-maker' element={<ChargerMaker />}>
                            <Route index element={<ChargerMakerList />} />
                            <Route path='create' element={<AddChargerMaker />} />
                        </Route>
                        <Route path='/charger-type' element={<ChargerType />}>
                            <Route index element={<ChargerTypeList />} />
                            <Route path='create' element={<AddChargerType />} />
                        </Route>
                        <Route path='/charger-model' element={<ChargerModel />}>
                            <Route index element={<ChargerModelList />} />
                            <Route path='create' element={<AddChargerModel />} />
                        </Route>
                        <Route path='/charger-protocol' element={<ChargerProtocol />}>
                            <Route index element={<ChargerProtocolList />} />
                            <Route path='create' element={<AddChargerProtocol />} />
                        </Route>
                        <Route path='/chargers-history' element={<HistoryCharger />}>
                            <Route index element={<ListAllHistoryCharger />} />
                            <Route path=':id' element={<DetailHistoryCharger />} />
                            <Route path='booking/:id' element={<DetailBookingCancel />} />
                        </Route>
                        <Route path='/one-time-payment' element={<OneTimePayment />}>
                            <Route index element={<ListOneTimePayment />} />
                            <Route path=':id' element={<DetailOneTimePayment />} />
                        </Route>
                        <Route path='/hold-bookings' element={<HoldBooking />}>
                            <Route index element={<ListHoldBooking />} />
                        </Route>
                        <Route path='/monthly-fees' element={<MonthlyFees />}>
                            <Route index element={<ListMonthlyFees />} />
                        </Route>
                        
                        <Route path='/schedule-bookings' element={<ScheduleBooking />}>
                            <Route index element={<ListScheduleBoooking />} />
                            <Route path=':id' element={<DetailScheduleBooking />} />
                        </Route>
                        <Route path='/users' element={<ListUser />} />
                        <Route path='/users/detail/:id' element={<DetailUser />} />
                        <Route path='/accounts' element={<AccountSetting />}>
                            <Route path='create' element={<CreateAccount />} />
                            <Route path=':id/edit' element={<EditAccount />} />
                        </Route>
                        <Route path='/coupons' element={<Coupon />}>
                            <Route index element={<CouponList />} />
                            <Route path='create' element={<CreateCoupon />} />
                            <Route path='detail/:uuid' element={<DetailCoupon />} />
                            <Route path='history' element={<CouponHistoryList />} />
                            <Route path='history/detail/:id' element={<CouponHistoryDetail />} />
                        </Route>
                        <Route path='/notification' element={<Notification />}>
                            <Route index element={<NotificationList />} />
                            <Route path='create' element={<AddNotification />} />
                            <Route path='detail/:id' element={<DetailNotification />} />
                            <Route path=':id/edit' element={<EditNotification />} />
                        </Route>
                        <Route path='/category' element={<Category />}>
                            <Route index element={<CategoryList />} />
                        </Route>
                        <Route element={<Report />}>
                            <Route path='/search' element={<FacilityChargerReport />} />
                        </Route>
                        <Route path='/map' element={<Map />} />
                        <Route path='/log' element={<LogList />} />
                        <Route path='/log/detail/:uuid' element={<DetailLog />} />
                    </Route>

                    <Route path='/login' element={<Login />} />
                    <Route path='/forbidden' element={<Forbidden />} />
                    <Route path='/internal-sever' element={<InternalServer />} />
                    <Route path='/404' element={<NotFound />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
            <SuccessModal />
            <>
                {t('facility_detial_12.1') === 'Facility Details' ? (
                    <style>
                        {
                            `.ant-form-item-label>label.ant-form-item-required::before {
                                content: "*" !important;
                            }`
                        }
                    </style>
                ) : null
                }
            </>
        </BrowserRouter>
    );
};

export default App;
