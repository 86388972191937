import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationEnglish from "./Translation/English/translation.json";
import translationJapan from "./Translation/Japan/translation.json";

//---Using translation
// const resources = {
//     en: {
//         translation: translationEnglish,
//     },
//     es: {
//         translation: translationSpanish,
//     },
//     fr: {
//         translation: translationFrench,
//     },
// }

//---Using different namespaces
const resources = {
    en: {
        home: translationEnglish,
    },
    jp: {
        home: translationJapan,
    },
}


i18next
.use(initReactI18next)
.init({
  resources,
  lng:"jp", //default language
});

var lang = window.navigator.languages ? window.navigator.languages[0] : null;
lang = window.sessionStorage.getItem('lang');
//eslint-disable-next-line
if(lang != undefined){
    var shortLang = lang;

    if (shortLang.indexOf('-') !== -1)
        shortLang = shortLang.split('-')[0];
    
    if (shortLang.indexOf('_') !== -1)
        shortLang = shortLang.split('_')[0];

    i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: shortLang, //default language
        //   lng:"en", //default language
        fallbackLng: "jp",
    });
}

export default i18next;