import { getAxiosClientWithToken, getAxiosClientWithJWTToken } from "api";
import { handleFormData } from 'lib/utils';
import {
    BookingList,
    CancelBooking,
    ErrorsStatusParams,
    ListChargerRequest,
    ListPlaceRequest,
    ChargingHistory,
    SearchPlaceRequest,
    UpdateMasterSlave,
    ListStatisticRequestByCharger,
    NittoChargerStatus,
    SendConnectNitto,
    KazamChargerStatus,
    SendConnectKazam,
    ListBookingHistoryRequest,
    ScheduleBookingRequest,
} from 'redux/slice/chargeSlice';
import { CreateChargerData, CSVexport, UpdateStatusCharger, TurnOnOff, RestrictedCharger, StatisticData, ChangeFacilityData, chargerModelParams } from 'types';

export const getListCharger = (params: ListChargerRequest) => {
    return getAxiosClientWithJWTToken().get('charger/list', { params });
};

export const createCharger = (params: CreateChargerData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/create`, params);
};

export const createRestrictHour = (params: RestrictedCharger) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/admin/booking/create`, params);
};

export const editCharger = (params: { data: CreateChargerData; id?: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/update`, params.data);
};

export const changeFacility = (params: ChangeFacilityData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/change-facility`, params);
};

export const deleteCharger = (params: { uuid: string, lang: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.delete(`/charger/delete?uuid=${params.uuid}&lang=${params.lang}`);
};

export const detailCharger = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/charger/details?uuid=${params.id}`);
};

export const getSearchPlaceInListCharger = (params: SearchPlaceRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('facility/list-uuid-name', { params });
};

export const getPlaceListCoupon = (params: SearchPlaceRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('facility/coupon-list-uuid-name', { params });
};

export const getChargerOperationStatus = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charger-operation-status');
};
export const getChargerType = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger-type/list');
};

export const getListChargerProtocol = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('charger-protocol/list');
};

export const getChargerMakerType = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/charger-maker/list`);
};

export const getChargerStatus = (params: { type?: number }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/charger-status', { params });
};

export const getChargerMaker = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get("/charger-maker/list");
};

export const listByPlaceInCharger = (params: ListPlaceRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/place/list-place-selected', { params });
};

export const exportCSV = (params: CSVexport) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charge-history/csv', { params });
};

export const exportChargerHistoryCSV = (params: CSVexport, id?: string | number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/admin/charger/${id}/export`, { params });
};

export const getListBooking = (params: BookingList) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/admin/booking', { params });
};

export const cancelBookingReservationList = (params: CancelBooking) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/admin/booking/cancel`, params);
};

export const updateMasterSlaveFlagCharger = (params: UpdateMasterSlave) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params);
    return restClient.post(`/admin/charger/${params.id}/update-master-slave-flag`, formData);
};

export const getListErrorsStatus = (params: ErrorsStatusParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/error-status', { params });
};

export const getListErrorsLog = (params: { day_search?: string; error_status_id?: string | number }, id?: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/error-log`, { params });
};

export const updateStatusCharger = (params: { data: UpdateStatusCharger; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`/admin/charger/${params.id}/update-status`, formData);
};

export const getChargerModel = (params: chargerModelParams) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('charger-model/list?charger_maker_id=' + params.charger_maker_id +
        '&charger_type_id=' + params.charger_type_id);
};

export const getItemPlaceStatistic = (params: StatisticData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`summary/statistic-v2`, { params });
};

export const fetchChargingHistory = (params: any) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('charge-history', { params });
};

export const fetchChargingHistoryByCharger = (params: ChargingHistory, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/charging-history`, { params });
};

export const getStatisticHistoryByCharger = (params: ListStatisticRequestByCharger, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/statistic-charging-history`, { params });
};

export const stopCharger = (params: TurnOnOff) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`charger/switching`, params);
};

export const openCharger = (params: TurnOnOff) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`charger/switching`, params);
};

// NITTO
// unknown api
export const connectNitto = (params: { data: SendConnectNitto; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`admin/charger/${params.id}/connect-nitto`, formData);
};

export const getStatusNitto = (params: { charger_uuid: number | string, lang: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`charger/nitto`, params);
};

export const getListStatusNitto = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-status-nitto`, { params });
};

export const getListSupplyStatus = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-supply-status`, { params });
};

export const startNitto = (params: NittoChargerStatus, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/start-charging`, params);
};

export const stopNitto = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/stop-charging`);
};

// unknown api
export const getInfoNitto = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/info-nitto`, { params });
};

// Kazam
// unknown api
export const connectKazam = (params: { data: SendConnectKazam; id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    const formData = handleFormData(params.data);
    return restClient.post(`admin/charger/${params.id}/connect-kazam`, formData);
};

export const getStatusKazamCharger = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/status-kazam-charger`);
};

export const getStatusKazamCharging = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${id}/status-kazam-charging`);
};

export const getListStatusKazamCharger = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-status-kazam-charger`, { params });
};

export const getListStatusKazamCharging = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-status-kazam-charging`, { params });
};

export const getListKazamSupplyStatus = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/list-kazam-supply-status`, { params });
};

export const startKazam = (params: KazamChargerStatus, id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/start-kazam-charging`, params);
};

export const stopKazam = (id: number | string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`admin/charger/${id}/stop-kazam-charging`);
};

// unknown api
export const getInfoKazam = (params: { id?: number | string }) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`admin/charger/${params.id}/info-kazam`, { params });
};

export const listChargerBookingHistory = (params: ListBookingHistoryRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger/hold-booking-list', { params });
};

export const listScheduleBookingHistory = (params: ScheduleBookingRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger/schedule-booking/list', { params });
};

export const getDetailScheduleBooking = (params: { id?: Number | string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/charger/schedule-booking/details?uuid=${params.id}`);
};

export const getMonthlyFeesList = (params: ChargingHistory) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/history/monthly-fees`, { params });
};
