import { getAxiosClientWithJWTToken, getAxiosClientWithToken } from 'api';
import {ListCouponRequest, StatisticCouponRequest} from "../redux/slice/couponSlice";
import {CreateCouponData} from "../types";

export const createCoupon = (params: CreateCouponData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/coupon/create`, params);
};

export const getListCoupon = (params: ListCouponRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/coupon', { params });
};

export const getSearchListCoupon = (params: ListCouponRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/coupon/search', { params });
};

export const getDetailCoupon = (params: {uuid: string, lang: string}) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/coupon/details', {params});
};

export const deleteCoupon = (params: {lang: string, uuid: string, status: number}) => {
    return getAxiosClientWithJWTToken().post('coupon/enable-disable', params);
};

export const getHistoryListCoupon = (params: ListCouponRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/coupon/history', { params });
};

export const getStatisticCoupon = (params: StatisticCouponRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/coupon/history/statistics', { params });
};
