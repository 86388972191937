import { getAxiosClientWithJWTToken } from 'api';
import { ListAuditTrailRequest } from 'redux/slice/ChargerHistorySlice';


export const getListAuditTrail = (params: ListAuditTrailRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    //return restClient.get(`/log?page=` + params.page + `&lang=` + params.lang);
    return restClient.get("/log", { params });
};

export const getDetailAuditTrail = (params: {uuid: string, lang: string}) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/log/details', {params});
};


