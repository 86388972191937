import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    deleteSection,
    getChartData,
    getDetailBookingCancel,
    getDetailChargerHistory,
    getListCityByPrefecture,
    getPrefectureChargerHistory,
    getStatisticHistory,
    getDashboardSummary,
    deleteSessionSection
} from 'api/chargerHistory';
import {
    getListAuditTrail, getDetailAuditTrail
} from 'api/auditTrail';

import { logout } from './authSlice';
import { RootState } from '../store';
import { Constant } from 'config/constant';
import { chargerHistoryDataChartType, AuditTrailData } from 'types';

export type ListAuditTrailRequest = {
    page?: number;
    lang?: string;
    token_user?: string;
    start_time?: string,
    end_time?: string;
};

export type DetailChargerHistory = {
    id: number;
    place_id: number;
    place_code: string;
    reservation_exceed_cancelled_by: string;
    place: string;
    paid_off: number;
    place_address: string;
    building_name: string;
    prefecture: string;
    city: string;
    user_id: number;
    user_code: string;
    user_first_name: string;
    user_last_name: string;
    user_name: string;
    charger_id: number;
    charger_code: string;
    charger_type_id: number;
    charger_type: string;
    charger_type_icon_url: string;
    charging_status_id: number;
    charging_status: number;
    charging_date_start_jp: string;
    charging_time_start: string;
    charging_date_end_jp: string;
    charging_time_end: string;
    charging_time_start_jp: string;
    charging_time_end_jp: string;
    charging_time: number;
    charging_amount: number;
    charger_protocol: string;
    electricity_fee: number;
    power_used: number;
    electric_bill: number;
    charging_bill: number;
    charging_fee: number;
    error_log?: string;
    booking_status_id: number;
    charging_method: number;
    place_uuid: string;
    charger_uuid: string;
    charger_short_code: string;
    facility_name: string;
    device_code: string;
    phone_number: string;
    location: string;
    charging_power: number;
    final_charging_fee: number;
    final_charging_time: number;
    final_charging_time_min: number;
    transcation_id: string;
    order_id: string;
    end_reason : string;
    user_uuid: string;
    charger_reserve_uuid: string;
};

export type Prefecture = {
    code: string;
    name_kana: string;
    name: string;
};

export type City = {
    prefecture_code: string;
    city_code: string;
    city_name_kana: string;
    city_name: string;
};

export type ChartCount = {
    totalCharging: ChargingTime[];
    totalChargingTime: TotalCharging[];
};

export type ChargingTime = {
    total_charging_time: number;
    date: string;
};

export type TotalCharging = {
    total_charging: number;
    date: string;
};


type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ChartData = {
    total_charging_bill: number;
    total_electric_bill: number;
    total_charging: number;
    total_charging_time: number;
    date: string;

}

export type DashboardSummaryData = {
    totalFacility: number;
    totalCharger: number;
    totalChargerData:{
        totalChargerComplete: number;
        totalChargingHour: number;
        totalFees: number;
        totalElectricPower: number;
    }
}

export type ChargerBooking = {
    total_number_charge: number;
    total_charging_hour: number;
    total_charging_fees: number;
    total_electric_power: number;
}

export type ChargingBill = {
    total_charging_bill: number;
    date: string;
};

export type ElectricBill = {
    total_electric_bill: number;
    date: string;
};

export type ListStatisticHistoryRequest = {
    place_id?: number[];
    place_code?: number | string;
    start_time?: string;
    end_time?: string;
    charging_tab?: string | number;
};

export type ChargerHistoryData = {
    status: number;
    detailChargerHistory: {
        success: boolean;
        message: string;
        chargerHistory: DetailChargerHistory;
        loading: boolean;
        error: boolean;
        status: number;
        delete_available: boolean;
    };
    deleteSection: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    prefecture: {
        success: boolean;
        message: string;
        data: Prefecture[];
        loading: boolean;
        error: boolean;
        status: number;
    };
    listAuditTrail:{
        success: boolean;
        message: string;
        data: AuditTrailData[];
        loading: boolean;
        error: boolean;
        status: number;
        pagination: Pagination;
    };
    detailAuditTrail: {
        success: boolean;
        error: boolean;
        messages: string ;
        loading: boolean;
        data: AuditTrailData;
    };
    cityByPrefecture: {
        success: boolean;
        message: string;
        data: City[];
        loading: boolean;
        error: boolean;
    };
    chartData: {
        success: boolean;
        message: string;
        data: ChartData[];
        loading: boolean;
        error: boolean;
    };
    dashboardSummaryData: {
        success: boolean;
        message: string;
        data: DashboardSummaryData;
        loading: boolean;
        error: boolean;
    };
    chargerBooking: {
        success: boolean;
        message: string;
        data: ChargerBooking[];
        loading: boolean;
        error: boolean;
    };

    deleteSessionSection: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };

};

export const destorySessionSection = createAsyncThunk(
    '/admin/charging-history/delete/session',
    async (params: { charging_hisotory_uuid: string, lang:string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteSessionSection(params);
            const { success } = response.data;

            if (success) {
                dispatch(setDeleteSessionSection(response.data));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDeleteSessionSection(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getDetailChargerHistoryData = createAsyncThunk(
    'admin/charging-history/:id',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailChargerHistory(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailChargerHistory(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDetailChargerHistory(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getDetailBookingCancelData = createAsyncThunk(
    'admin/booking/:id',
    async (params: { id: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailBookingCancel(params);
            const { success } = response.data;
            if (success) {
                dispatch(setDetailBookingCancel(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDetailBookingCancel(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getPrefectureData = createAsyncThunk('prefecture', async (_, { dispatch, rejectWithValue }) => {
    try {
        let response = await getPrefectureChargerHistory();
        const { success } = response.data;
        if (success) {
            dispatch(setPrefectureChargerHistory(response.data));
            return true;
        }
        return rejectWithValue(false);
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setPrefectureChargerHistory(error));
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchListAuditTrail = createAsyncThunk('/auditTrail/log', async (params: ListAuditTrailRequest, { dispatch }) => {
    try {
        const response = await getListAuditTrail(params);
        const {
            data = [],
            total = 0,
            per_page = Constant.PAGE_SIZE_NEW,
            current_page = Constant.DEFAULT_PAGE,
            last_page = Constant.DEFAULT_PAGE,
            success,
        } = response.data;
       
        if (success) {
            dispatch(
                // setListAuditTrail(response.data)
                setListAuditTrail({ data: data, pagination: { total, perPage: per_page, currentPage: current_page, lastPage: last_page } })
            );
            return true;
       }
        return false;
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListAuditTrail(error));
    }
    return false;
});

export const getDetailAuditTrailData = createAsyncThunk(
    'details/log/:uuid',
    async (params: {uuid: string, lang: string}, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailAuditTrail(params);
            const { success } = response?.data;
            if (success) {
                dispatch(setDetailAuditTrail(response?.data));
                return success;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDetailAuditTrail(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getListCityByPrefectureData = createAsyncThunk(
    'prefecture/city',
    async (params: { prefecture_code: number | string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await getListCityByPrefecture(params);
            const { success } = response.data;
            if (success) {
                dispatch(setCityByPrefecture(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const destroySection = createAsyncThunk(
    '/admin/charging-history/:id/delete',
    async (id: number | string, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteSection(id);
            return response;
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchDataChart = createAsyncThunk(
    'admin/charging-history/data-chart',
    async (params: chargerHistoryDataChartType, { dispatch, rejectWithValue }) => {
        try {
            let response = await getChartData(params);
            dispatch(setChartData(response.data));
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchDashboardSummary = createAsyncThunk(
    'admin/charging-history/dashboard',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDashboardSummary(params);
            dispatch(setDashboardSummaryData(response.data));
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchHistoryStatistic = createAsyncThunk(
    'admin/charger/statistic',
    async (params: ListStatisticHistoryRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getStatisticHistory(params);
            dispatch(setListHistoryStatistic(response.data));
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const chargerHistorySlice = createSlice({
    name: 'chargerHistory',
    initialState: {
        detailChargerHistory: {
            success: false,
            message: '',
            chargerHistory: {} as DetailChargerHistory,
            loading: false,
            error: false,
            delete_available: false,
        },
        prefecture: {
            success: false,
            message: '',
            data: [] as Prefecture[],
            loading: false,
            error: false,
        },
        listAuditTrail: {
            success: false,
            message: '',
            data: [] as AuditTrailData[],
            loading: false,
            error: false,
        },
        detailAuditTrail: {
            success: false,
            error: false,
            messages: '',
            loading: false,
            data: {} as AuditTrailData,
        },

        cityByPrefecture: {
            success: false,
            message: '',
            data: [] as City[],
            loading: false,
            error: false,
        },
        deleteSection: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        chartData: {
            success: false,
            message: '',
            data: [] as ChartData[],
            loading: false,
            error: false,
        },
        dashboardSummaryData: {
            success: false,
            message: '',
            data: {} as DashboardSummaryData,
            loading: false,
            error: false,
        },
        chargerBooking: {
            success: false,
            message: '',
            data: [] as ChargerBooking[],
            loading: false,
            error: false,
        },
        deleteSessionSection: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },

    } as ChargerHistoryData,
    reducers: {
        resetDetailHistoryState: (state: ChargerHistoryData) => {
            state.detailChargerHistory.status = Constant.DEFAULT_STATUS;
            state.detailChargerHistory = {
                loading: false,
                success: false,
                error: false,
                message: '',
                status: 0,
                chargerHistory: {} as DetailChargerHistory,
                delete_available: false,
            };
        },
        resetDeleteSectionState: (state: ChargerHistoryData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.deleteSection = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },

        resetDeleteSessionSection: (state: ChargerHistoryData) => {
            state.status = Constant.DEFAULT_STATUS;
            state.deleteSessionSection = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },

        setDetailChargerHistory: (state: ChargerHistoryData, { payload }) => {
            state.detailChargerHistory.chargerHistory = payload?.data;
            state.detailChargerHistory.success = payload?.success;
            state.detailChargerHistory.message = payload?.message;
            state.detailChargerHistory.status = payload?.response?.status;
            state.detailChargerHistory.delete_available = payload?.delete_available;
        },
        setDetailBookingCancel: (state: ChargerHistoryData, { payload }) => {
            state.detailChargerHistory.chargerHistory = payload?.data;
            state.detailChargerHistory.success = payload?.success;
            state.detailChargerHistory.message = payload?.message;
            state.detailChargerHistory.status = payload?.response?.status;
        },
        setPrefectureChargerHistory: (state: ChargerHistoryData, { payload }) => {
            state.prefecture.data = payload?.data;
            state.prefecture.success = payload?.success;
            state.prefecture.message = payload?.message;
            state.prefecture.status = payload?.response?.status;
        },
        setListAuditTrail: (state: ChargerHistoryData, { payload }) => {
            const { pagination } = payload;
            state.listAuditTrail.pagination = pagination;
            state.listAuditTrail.data = payload?.data;
            state.listAuditTrail.success = payload?.success;
            state.listAuditTrail.message = payload?.message;
            state.listAuditTrail.status = payload?.response?.status;
        },    
        setDetailAuditTrail: (state: ChargerHistoryData, { payload }) => {
            state.detailAuditTrail.data = payload?.data;
            state.detailAuditTrail.success = payload?.success;
            state.detailAuditTrail.messages = payload?.message;
            state.status = payload?.response?.status;
        },

        setCityByPrefecture: (state: ChargerHistoryData, { payload }) => {
            state.cityByPrefecture.data = payload?.data;
            state.cityByPrefecture.success = payload?.success;
            state.cityByPrefecture.message = payload?.message;
        },
        setChartData: (state: ChargerHistoryData, { payload }) => {
            state.chartData.data = payload?.data;
            state.chartData.success = payload?.success;
            state.chartData.message = payload?.message;
        },
        setDashboardSummaryData: (state: ChargerHistoryData, { payload }) => {
            state.dashboardSummaryData.data = payload?.data;
            state.dashboardSummaryData.success = payload?.success;
            state.dashboardSummaryData.message = payload?.message;
        },      
        setChartCount: (state: ChargerHistoryData, { payload }) => {
            state.chartData.data = payload?.data;
            state.chartData.success = payload?.success;
            state.chartData.message = payload?.message;
        },
        setListHistoryStatistic: (state: ChargerHistoryData, { payload }) => {
            state.chargerBooking.data = payload?.data;
            state.chargerBooking.success = payload?.success;
            state.chargerBooking.message = payload?.message;
        },
        setDeleteSessionSection: (state: ChargerHistoryData, { payload }) => {
            state.status = payload?.response?.status;
            state.deleteSessionSection.messages = payload?.message;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getDetailChargerHistoryData.pending, (state: ChargerHistoryData) => {
                state.detailChargerHistory.loading = true;
            })
            .addCase(getDetailChargerHistoryData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = false;
                state.detailChargerHistory.error = true;
                state.detailChargerHistory.message = payload as string;
            })
            .addCase(getDetailChargerHistoryData.fulfilled, (state: ChargerHistoryData, action) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = true;
                state.detailChargerHistory.error = false;
            })
            .addCase(getDetailBookingCancelData.pending, (state: ChargerHistoryData) => {
                state.detailChargerHistory.loading = true;
            })
            .addCase(getDetailBookingCancelData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = false;
                state.detailChargerHistory.error = true;
                state.detailChargerHistory.message = payload as string;
            })
            .addCase(getDetailBookingCancelData.fulfilled, (state: ChargerHistoryData, action) => {
                state.detailChargerHistory.loading = false;
                state.detailChargerHistory.success = true;
                state.detailChargerHistory.error = false;
            })
            .addCase(getPrefectureData.pending, (state: ChargerHistoryData) => {
                state.prefecture.loading = true;
            })
            .addCase(getPrefectureData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.prefecture.loading = false;
                state.prefecture.success = false;
                state.prefecture.error = true;
                state.detailChargerHistory.message = payload as string;
            })
            .addCase(getPrefectureData.fulfilled, (state: ChargerHistoryData, action) => {
                state.prefecture.loading = false;
                state.prefecture.success = true;
                state.prefecture.error = false;
            })
            .addCase(fetchListAuditTrail.pending, (state: ChargerHistoryData) => {
                state.listAuditTrail.loading = true;
            })
            .addCase(fetchListAuditTrail.rejected, (state: ChargerHistoryData, { payload }) => {
                state.listAuditTrail.loading = false;
                state.listAuditTrail.success = false;
                state.listAuditTrail.error = true;
            })
            .addCase(fetchListAuditTrail.fulfilled, (state: ChargerHistoryData, action) => {
                state.listAuditTrail.loading = false;
                state.listAuditTrail.success = true;
                state.listAuditTrail.error = false;
            })
            .addCase(getDetailAuditTrailData.pending, (state: ChargerHistoryData) => {
                state.detailAuditTrail.loading = true;
            })
            .addCase(getDetailAuditTrailData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.detailAuditTrail.loading = false;
                state.detailAuditTrail.success = false;
                state.detailAuditTrail.error = true;
                state.detailAuditTrail.messages = payload as string;
            })
            .addCase(getDetailAuditTrailData.fulfilled, (state: ChargerHistoryData) => {
                state.detailAuditTrail.loading = false;
                state.detailAuditTrail.success = true;
                state.detailAuditTrail.error = false;
            })
            .addCase(getListCityByPrefectureData.pending, (state: ChargerHistoryData) => {
                state.cityByPrefecture.loading = true;
            })
            .addCase(getListCityByPrefectureData.rejected, (state: ChargerHistoryData, { payload }) => {
                state.cityByPrefecture.loading = false;
                state.cityByPrefecture.success = false;
                state.cityByPrefecture.error = true;
                state.cityByPrefecture.message = payload as string;
            })
            .addCase(getListCityByPrefectureData.fulfilled, (state: ChargerHistoryData, action) => {
                state.cityByPrefecture.loading = false;
                state.cityByPrefecture.success = true;
                state.cityByPrefecture.error = false;
            })
            .addCase(destroySection.pending, (state: ChargerHistoryData) => {
                state.deleteSection = {
                    loading: true,
                    success: false,
                    error: false,
                    messages: '',
                };
            })
            .addCase(destroySection.rejected, (state: ChargerHistoryData, { payload }) => {
                state.deleteSection = {
                    loading: false,
                    success: false,
                    error: true,
                    messages: 'error',
                };
            })
            .addCase(destroySection.fulfilled, (state: ChargerHistoryData) => {
                state.deleteSection = {
                    loading: false,
                    success: true,
                    error: false,
                    messages: '',
                };
            })
            .addCase(fetchDataChart.pending, (state: ChargerHistoryData) => {
                state.chartData.loading = true;
            })
            .addCase(fetchDataChart.rejected, (state: ChargerHistoryData, { payload }) => {
                state.chartData.loading = false;
                state.chartData.success = false;
                state.chartData.error = true;
            })
            .addCase(fetchDataChart.fulfilled, (state: ChargerHistoryData, action) => {
                state.chartData.loading = false;
                state.chartData.success = true;
                state.chartData.error = false;
            })

            .addCase(destorySessionSection.pending, (state: ChargerHistoryData) => {
                state.deleteSessionSection.loading = true;
                state.deleteSessionSection.success = false;
                state.deleteSessionSection.error = false;
            })
            .addCase(destorySessionSection.rejected, (state: ChargerHistoryData, {payload}) => {
                state.deleteSessionSection.loading = false;
                state.deleteSessionSection.success = false;
                state.deleteSessionSection.error = true;
                state.deleteSessionSection.messages = payload as string;

               //console.log("444 payload=>", payload);
            })
            .addCase(destorySessionSection.fulfilled, (state: ChargerHistoryData) => {
                state.deleteSessionSection.loading = false;
                state.deleteSessionSection.success = true;
                state.deleteSessionSection.error = false;
            })

            .addCase(fetchDashboardSummary.pending, (state: ChargerHistoryData) => {
                state.dashboardSummaryData.loading = true;
            })
            .addCase(fetchDashboardSummary.rejected, (state: ChargerHistoryData, { payload }) => {
                state.dashboardSummaryData.loading = false;
                state.dashboardSummaryData.success = false;
                state.dashboardSummaryData.error = true;
            })
            .addCase(fetchDashboardSummary.fulfilled, (state: ChargerHistoryData, action) => {
                state.dashboardSummaryData.loading = false;
                state.dashboardSummaryData.success = true;
                state.dashboardSummaryData.error = false;
            })
            .addCase(fetchHistoryStatistic.pending, (state: ChargerHistoryData) => {
                state.chargerBooking.loading = true;
            })
            .addCase(fetchHistoryStatistic.rejected, (state: ChargerHistoryData, { payload }) => {
                state.chargerBooking.loading = false;
                state.chargerBooking.success = false;
                state.chargerBooking.error = true;
            })
            .addCase(fetchHistoryStatistic.fulfilled, (state: ChargerHistoryData, action) => {
                state.chargerBooking.loading = false;
                state.chargerBooking.success = true;
                state.chargerBooking.error = false;
            });
    },
});

export const {
    setDetailChargerHistory,
    resetDetailHistoryState,
    resetDeleteSessionSection,
    setPrefectureChargerHistory,
    setCityByPrefecture,
    resetDeleteSectionState,
    setChartData,
    setDashboardSummaryData,
    setDetailBookingCancel,
    setListHistoryStatistic,
    setListAuditTrail,
    setDetailAuditTrail,
    setDeleteSessionSection
} = chargerHistorySlice.actions;
export const ChargerHistorySelector = (state: RootState) => state.chargerHistory;
