import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerMakerData} from 'types'

export const createChargerMaker= (params: RegisterChargerMakerData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_makers/create`, params);
};

export const getListChargerMaker = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger-maker/list', { params });
};

export const UpdateChargerMaker = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_makers/update`, params);
};

export const deleteChargerMaker = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/charger_makers/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};