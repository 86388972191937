import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerProtocolData} from 'types'

export const createChargerProtocol= (params: RegisterChargerProtocolData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/protocol/create`, params);
};

export const getListChargerProtocol = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger-protocol/list', { params });
};

export const UpdateChargerProtocol = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger/protocol/update`, params);
};

export const deleteChargerProtocol = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/charger/protocol/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};