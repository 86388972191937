import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Constant } from 'config/constant';
import { RootState } from 'redux/store';
import { getErrorMessage } from 'api';
import {exportCSV, getDetailCouponUser, getListCouponUser} from "../../api/couponUser";
import {CouponUserData, CouponUserStatistic} from "../../types";
import { downloadCsv } from 'lib/utils';
import moment from 'moment';
import { logout } from './authSlice';

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ListCouponUser = {
    error: boolean;
    loading: boolean;
    success: boolean;
    coupon: CouponUserData[];
    pagination: Pagination;
    status: string;
    statistic: CouponUserStatistic;
};

export type CouponUserState = {
    status: number;
    couponData: CouponUserData[];
    listCouponUser: ListCouponUser;
    detail: {
        success: boolean;
        error: boolean;
        messages: string | CouponUserData;
        loading: boolean;
        dataDetail: CouponUserData;
    };
    downloadCSVData: {
        error: boolean;
        loading: boolean;
        success: boolean;
        data: string
    };
};

export type ListCouponUserRequest = {
    page?: number;
    keyword?: string;
    type?: string;
    issuer?: string,
    place_id?: string;
    coupon_id?: string[];
    start_time?: string,
    end_time?: string;
    name?: string;
    lang?: string;
};

export const fetchListCouponUser = createAsyncThunk(
    'admin/coupon-user',
    async (params: ListCouponUserRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListCouponUser(params);
            const {
                data = [],
                total = 0,
                per_page = Constant.PAGE_SIZE_NEW,
                current_page = Constant.DEFAULT_PAGE,
                last_page = Constant.DEFAULT_PAGE,
                success,
                statistic,
            } = response.data;
            if (success) {
                dispatch(setListCoupon({ coupon: data, pagination: { total, perPage: per_page, currentPage: current_page, lastPage: last_page }, statistic  }));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setListCoupon(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);
export const getDetailCouponUserData = createAsyncThunk(
    'admin/coupon-user/:id',
    async (params: {id: string, lang: string}, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailCouponUser(params.id, params.lang);
            const { success } = response?.data;
            if (success) {
                dispatch(setDetailCoupon(response?.data));
                return success;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDetailCoupon(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const exportCouponHistoryCSV = createAsyncThunk(
    '/admin/coupons-user/export',
    async (params: ListCouponUserRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await exportCSV(params);
            dispatch(setDownloadCSV(response.data));
            let startDate =moment().startOf('month').format('YYYY-MM-DD');
            let endDate =moment(new Date()).format("YYYY-MM-DD");
            let fileName = "coupon-history-csv"   
            downloadCsv(response.data.data,startDate,endDate,fileName);
            return response.data;
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const couponUserSlice = createSlice({
    name: 'couponUser',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        couponData: [],
        listCouponUser: {
            error: false,
            loading: false,
            success: false,
            coupon: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
            status: '',
            statistic: {
                date_display: "",
                total: 0,
                unused_total: 0,
                used_total: 0,
                amount_total: null,
                amount_used: 0,
                amount_red_flag: false,
                time_total: 0,
                time_red_flag: false,
                count_total: 0,
                count_red_flag: false,
            }
        },
        detail: {
            success: false,
            error: false,
            messages: '',
            loading: false,
            dataDetail: {} as CouponUserData,
        },
        downloadCSVData: {
            error: false,
            loading: false,
            success: false,
            data: '',
        },
    } as CouponUserState,
    reducers: {
        setListCoupon: (state: CouponUserState, { payload }) => {
            const { coupon, pagination, statistic } = payload;
            state.listCouponUser.coupon = coupon;
            state.listCouponUser.pagination = pagination;
            state.listCouponUser.statistic = statistic;
        },
        setDetailCoupon: (state: CouponUserState, { payload }) => {
            state.detail.dataDetail = payload?.data;
            state.detail.success = payload?.success;
            state.detail.messages = payload?.message;
            state.status = payload?.response?.status;
        },
        setDownloadCSV: (state, { payload }) => {
            state.downloadCSVData.data = payload;
            state.downloadCSVData.success = payload?.success;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListCouponUser.pending, (state: CouponUserState) => {
                state.listCouponUser.loading = true;
            })
            .addCase(fetchListCouponUser.rejected, (state: CouponUserState) => {
                state.listCouponUser.loading = false;
                state.listCouponUser.success = false;
                state.listCouponUser.error = true;
            })
            .addCase(fetchListCouponUser.fulfilled, (state: CouponUserState) => {
                state.listCouponUser.loading = false;
                state.listCouponUser.success = true;
                state.listCouponUser.error = false;
            })
            .addCase(getDetailCouponUserData.pending, (state: CouponUserState) => {
                state.detail.loading = true;
            })
            .addCase(getDetailCouponUserData.rejected, (state: CouponUserState, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
                state.detail.messages = payload as string;
            })
            .addCase(getDetailCouponUserData.fulfilled, (state: CouponUserState) => {
                state.detail.loading = false;
                state.detail.success = true;
                state.detail.error = false;
            })
            .addCase(exportCouponHistoryCSV.pending, (state: CouponUserState) => {
                state.downloadCSVData.loading = true;
            })
            .addCase(exportCouponHistoryCSV.rejected, (state: CouponUserState) => {
                state.downloadCSVData.loading = false;
                state.downloadCSVData.success = false;
                state.downloadCSVData.error = true;
            })
            .addCase(exportCouponHistoryCSV.fulfilled, (state: CouponUserState) => {
                state.downloadCSVData.loading = false;
                state.downloadCSVData.success = true;
                state.downloadCSVData.error = false;
            });
    },
});

export const {
    setListCoupon,
    setDetailCoupon,
    setDownloadCSV,
} = couponUserSlice.actions;
export const couponUserSelector = (state: RootState) => state.couponUser;