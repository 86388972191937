import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Constant} from 'config/constant';
import {RootState} from 'redux/store';
import {getErrorMessage,} from 'api';
import { logout } from './authSlice';
import {
    RegisterChargerModelData
} from 'types';
import {createChargerModel, getListChargerModel, deleteChargerModel } from 'api/chargerModel';

export type chargerModelData = {
    uuid: string,
    name: string,
    charger_makers_name: string;
    charger_makers_en_name: string;
    charger_types_name: string;
    charger_types_en_name: string;
}
export type ListChargerModel = {
    error: boolean;
    loading: boolean;
    success: boolean;
    chargerModel: chargerModelData[];
    pagination: Pagination;
    status: string;
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ChargerModelState = {
    status: number;
    chargerModelData: {
        uuid: string;
        name: string;
    };
    listChargerModel: ListChargerModel;
    detailsChargerModel: chargerModelData;
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
}

export const chargerModelCreate = createAsyncThunk(
    'admin/charger-model/store',
    async (params: RegisterChargerModelData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createChargerModel(params);
            const { success, message } = response.data;
            if (success) {
                dispatch(setNewChargerModel(message));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setNewChargerModel(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const destoryChargerModel = createAsyncThunk(
    '/admin/charger-model/delete',
    async (params: { uuid: string, lang:string }, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteChargerModel(params);
            const { success, message } = response.data;
            if (success) {
                dispatch(setDeleteChargerModel(message));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDeleteChargerModel(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const chargerModelSlice = createSlice(
    {
        name: 'chargerModel',
        initialState: {
            status: Constant.DEFAULT_STATUS,
            chargerModelData: [],
            register: {
                loading: false,
                success: false,
                error: false,
                message: '',
            },
            delete: {
                success: false,
                error: false,
                messages: '',
                loading: false,
            },
            listChargerModel: {
                error: false,
                loading: false,
                success: false,
                pagination: {
                    total: 0,
                    perPage: Constant.PAGE_SIZE_NEW,
                    currentPage: Constant.DEFAULT_PAGE,
                    lastPage: Constant.DEFAULT_PAGE,
                },
                status: '',
            },
            listSearchPlace: {
                error: false,
                loading: false,
                success: false,
                places: [],
            }
        } as unknown as ChargerModelState,
        reducers:{
            resetRegisterState: (state: ChargerModelState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.register = {
                    success: false,
                    loading: false,
                    message: '',
                    error: false,
                };
            },
            setNewChargerModel: (state, action) => {
                state.register.message = action?.payload
            },
            setListChargerModel: (state: ChargerModelState, { payload }) => {
                const { pagination, chargerModel } = payload;
                state.listChargerModel.chargerModel = chargerModel;
                state.listChargerModel.pagination = pagination
            },
            setDetailsChargerModel:(state: ChargerModelState, {payload}) => {
                state.detailsChargerModel = {
                    name: payload.detailsChargerModel.name,
                    uuid: payload.detailsChargerModel.uuid,
                    charger_makers_name: payload.detailsChargerModel.charger_makers_name,
                    charger_makers_en_name: payload.detailsChargerModel.charger_makers_en_name,
                    charger_types_name: payload.detailsChargerModel.charger_types_name,
                    charger_types_en_name: payload.detailsChargerModel.charger_types_en_name
                }
            },
            setDeleteChargerModel: (state: ChargerModelState, { payload }) => {
                state.status = payload?.response?.status;
                state.delete.messages = payload
            },
            resetDeleteState: (state: ChargerModelState) => {
                state.status = Constant.DEFAULT_STATUS;
                state.delete = {
                    loading: false,
                    success: false,
                    error: false,
                    messages: '',
                };
            },
            resetListChargerModelState: (state: ChargerModelState) => {
                state.status = Constant.DEFAULT_STATUS;
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(chargerModelCreate.pending, (state: ChargerModelState) => {
                    state.register.loading = true;
                })
                .addCase(chargerModelCreate.rejected, (state: ChargerModelState, { payload }) => {
                    state.register.loading = false;
                    state.register.success = false;
                    state.register.error = true;
                    state.register.message = payload as string;
                })
                .addCase(chargerModelCreate.fulfilled, (state: ChargerModelState) => {
                    state.register.loading = false;
                    state.register.success = true;
                    state.register.error = false;
                })
                .addCase(fetchListChargerModel.pending, (state: ChargerModelState) => {
                    state.listChargerModel.loading = true;
                })
                .addCase(fetchListChargerModel.rejected, (state: ChargerModelState) => {
                    state.listChargerModel.loading = false;
                    state.listChargerModel.success = false;
                    state.listChargerModel.error = true;
                })
                .addCase(fetchListChargerModel.fulfilled, (state: ChargerModelState) => {
                    state.listChargerModel.loading = false;
                    state.listChargerModel.success = true;
                    state.listChargerModel.error = false;
                })
                .addCase(destoryChargerModel.pending, (state: ChargerModelState) => {
                    state.delete.loading = true;
                    state.delete.success = false;
                    state.delete.error = false;
                })
                .addCase(destoryChargerModel.rejected, (state: ChargerModelState, {payload}) => {
                    state.delete.loading = false;
                    state.delete.success = false;
                    state.delete.error = true;
                    state.delete.messages = payload as string;
                })
                .addCase(destoryChargerModel.fulfilled, (state: ChargerModelState) => {
                    state.delete.loading = false;
                    state.delete.success = true;
                    state.delete.error = false;
                })
        },
    }
);

export const fetchListChargerModel = createAsyncThunk(
    'admin/charger-model',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListChargerModel(params);
            const {
                data = [],
                total = 0,
                per_page: perPage = Constant.PAGE_SIZE,
                current_page: currentPage = Constant.DEFAULT_PAGE,
                last_page: lastPage = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                let m = 0;
                if(data.length>0) {
                    for (const elem of data) {
                        m+=1;
                        elem['no'] = m;
                    }
                }
                dispatch(setListChargerModel({ chargerModel: data, pagination: { total, perPage, currentPage, lastPage } }));
                return true;
            }
        } catch (error: any) {
            dispatch(setListChargerModel(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const {
    setListChargerModel,
    resetRegisterState,
    setNewChargerModel,
    setDeleteChargerModel,
    resetDeleteState,
    resetListChargerModelState,
    setDetailsChargerModel
} = chargerModelSlice.actions
export const chargerModelSelector = (state: RootState) => state.chargerModel;
