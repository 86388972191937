import {getAxiosClientWithJWTToken} from 'api';
import {RegisterWhiteUserData} from 'types'

export const createWhiteListUser = (params: RegisterWhiteUserData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/mobile-user/create-white-user`, params);
};

export const getListWhiteUser = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/mobile-user/list-white-user', { params });
};


export const deleteWhiteListUser = (params: { phone_number?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/mobile-user/delete-white-user`, {params});
};
