import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import { persistStore } from 'redux-persist';
import { ConfigProviderProps } from 'antd/lib/config-provider';
import locale from 'antd/lib/locale/ja_JP';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import "./i18n";

const persisTor = persistStore(store);

const antdConfig: ConfigProviderProps = {
    locale: locale,
    autoInsertSpaceInButton: false,
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persisTor} loading={null}>
            <ConfigProvider {...antdConfig}>
                <App />
            </ConfigProvider>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
