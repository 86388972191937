import {getAxiosClientWithJWTToken} from 'api';
import {RegisterFacilityTypeData} from 'types'

export const createFacilityType = (params: RegisterFacilityTypeData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/facilitytypes/create`, params);
};

export const getListFacilityType = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/facilitytypes', { params });
};

export const UpdateFacilityType = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/facilitytypes/update`, params);
};

export const deleteFacilityType = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/facilitytypes/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};
