import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SIDEBAR_WIDTH } from "config/constant";
import { Image, Layout, Menu, SiderProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsActiveMenu, useSelectedMenu } from "lib/utils";
import type { MenuProps } from "antd";
import PlaceIcon from "assets/images/place-icon.svg";
import AuthCardID from "assets/images/authCardID.svg";
import HomeIcon from "assets/images/sidebar/home.svg";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

type Props = SiderProps & {
  onCloseCollapsed?: () => void;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const Sidebar = (props: Props) => {
  const {
    breakpoint,
    collapsedWidth,
    onBreakpoint,
    collapsed,
    onCollapse,
    onCloseCollapsed,
    setIsCollapsed,
  } = props;
  const { pathname } = useLocation();
  const [openKeys, setOpenKeys] = useIsActiveMenu();
  const selectedMenus = useSelectedMenu();
  const navigate = useNavigate();
  const onOpenChange = (keys: string[]) => {
    let newOpenKeys = [];
    let lastKey = keys.pop();
    if (lastKey) {
      if (lastKey === "setting-notifications") {
        newOpenKeys.push(...keys);
      }
      newOpenKeys.push(lastKey);
    }
    setOpenKeys(newOpenKeys);
  };

  const { t } = useTranslation(["home"]);

  const menuItems: MenuProps["items"] = [
    {
      key: "home",
      icon: <Image src={HomeIcon} width={20} preview={false} />,
      className: "my-0 site-menu-item",
      label: `${collapsed ? "" : t("side_bar_3.1")}`,
    },
    // {
    //     key: 'map',
    //     icon: <Image src={MapIcon} width={20} preview={false} />,
    //     className: 'my-0 site-menu-item',
    //     label: `${collapsed ? '' : t('side_bar_3.2')}`,
    // },
    {
      key: "places",
      icon: <Image src={PlaceIcon} width={20} preview={false} />,
      className: "my-0 site-menu-item",
      label: `${collapsed ? "" : t("side_bar_3.3")}`,
    },
    {
      key: "auth-card-id",
      icon: <Image src={AuthCardID} width={20} preview={false} />,
      className: "my-0 site-menu-item",
      label: `${collapsed ? "" : t("authCardID.auth_card_id")}`,
    },
    // {
    //     key: 'users',
    //     icon: <Image src={UserIcon} width={20} preview={false} />,
    //     className: 'my-0 site-menu-item',
    //     label: `${collapsed ? '' : t('side_bar_3.4')}`,
    // },
    // {
    //     key: 'log',
    //     icon: <Image src={UserIcon} width={20} preview={false} />,
    //     className: 'my-0 site-menu-item',
    //     label: `${collapsed ? '' : t('dashboard.Track Information')}`,
    // },
    // isValidRoles(role, [SUPER_ADMIN]) ? {
    //     key: 'coupons-management',
    //     icon: <Image src={CouponIcon} width={20} preview={false} />,
    //     className: 'my-0 site-menu-item',
    //     label: `${collapsed ? '' : t('side_bar_3.クーポン管理')}`,
    //     theme: 'light',
    //     children: [
    //         {
    //             key: 'coupons',
    //             className: 'my-0 site-menu-item',
    //             label: t('side_bar_3.クーポン一覧・登録'),
    //         },
    //         {
    //             key: 'coupons-history',
    //             className: 'my-0 site-menu-item',
    //             label: t('side_bar_3.クーポン利用管理'),
    //         }
    //     ],
    // } : null,
    // {
    //     key: 'hold-bookings',
    //     icon: <Image src={holdBooking} width={20} preview={false} />,
    //     className: 'my-0  site-menu-item',
    //     label: `${collapsed ? '' : t('hold_booking.取置予約履歴')}`,
    //     theme: 'light',
    // },
    // {
    //     key: 'schedule-bookings',
    //     icon: <Image src={scheduleBooking} width={20} preview={false} />,
    //     className: 'my-0  site-menu-item',
    //     label: `${collapsed ? '' : t('scheduleBooking.スケジュール予約履歴')}`,
    //     theme: 'light',
    // },
    // {
    //     key: 'booking-management',
    //     icon: <Image src={holdBooking} width={20} preview={false} />,
    //     className: 'my-0 site-menu-item',
    //     label: `${collapsed ? '' : t('map_5.予約履歴')}`,
    //     theme: 'light',
    //     children: [
    //         {
    //             key: 'hold-bookings',
    //             className: 'my-0 site-menu-item',
    //             label: t('hold_booking.取置予約履歴'),
    //         },
    //         {
    //             key: 'schedule-bookings',
    //             className: 'my-0 site-menu-item',
    //             label: t('scheduleBooking.スケジュール予約履歴'),
    //         },
    //         {
    //             key: 'monthly-fee',
    //             className: 'my-0 site-menu-item',
    //             label: t('monthlyFees.Monthly Fees History'),
    //         }

    //     ],
    // },
    // {
    //     key: 'one-time-payment',
    //     icon: <Image src={OneTimePayment} width={20} preview={false} />,
    //     className: 'my-0  site-menu-item',
    //     label: `${collapsed ? '' : t('side_bar_3.ゲストモードの支払い')}`,
    //     theme: 'light',
    // },
    // {
    //     key: 'chargers-history',
    //     icon: <Image src={ChargeHistory} width={20} preview={false} />,
    //     className: 'my-0  site-menu-item',
    //     label: `${collapsed ? '' : t('side_bar_3.5')}`,
    //     theme: 'light',
    // },
    // isValidRoles(role, [SUPER_ADMIN, ADMIN]) ? {
    //     key: 'settings',
    //     icon: <Image src={SettingIcon} width={20} preview={false} />,
    //     className: 'my-0 site-menu-item',
    //     label: `${collapsed ? '' : t('side_bar_3.6')}`,
    //     theme: 'light',
    //     children: [
    //         // isValidRoles(role, [SUPER_ADMIN, ADMIN])
    //         //     ? {
    //         //         key: 'accounts',
    //         //         className: 'my-0 site-menu-item',
    //         //         label: t('side_bar_3.11'),
    //         //     }
    //         //     : null,
    //         // isValidRoles(role, [SUPER_ADMIN])
    //         //     ? {
    //         //         key: 'white-list',
    //         //         className: 'my-0 site-menu-item',
    //         //         label: t('side_bar_3.ホワイトリストユーザー'),
    //         //     }
    //         //     : null,
    //         isValidRoles(role, [SUPER_ADMIN])
    //             ? {
    //                 key: 'charger-protocol',
    //                 className: 'my-0 site-menu-item',
    //                 label: t('side_bar_3.18'),
    //             }
    //             : null,
    //         // isValidRoles(role, [SUPER_ADMIN])
    //         //     ? {
    //         //         key: 'facility-type',
    //         //         className: 'my-0 site-menu-item',
    //         //         label: t('side_bar_3.15'),
    //         //     }
    //         //     : null,
    //         isValidRoles(role, [SUPER_ADMIN])
    //             ? {
    //                 key: 'charger-maker',
    //                 className: 'my-0 site-menu-item',
    //                 label: t('side_bar_3.16'),
    //             }
    //             : null,
    //         isValidRoles(role, [SUPER_ADMIN])
    //             ? {
    //                 key: 'charger-type',
    //                 className: 'my-0 site-menu-item',
    //                 label: t('side_bar_3.17'),
    //             }
    //             : null,
    //         isValidRoles(role, [SUPER_ADMIN])
    //             ? {
    //                 key: 'charger-model',
    //                 className: 'my-0 site-menu-item',
    //                 label: t('side_bar_3.充電器型番'),
    //             }
    //             : null,

    //     ],
    // } : null,
    // isValidRoles(role, [SUPER_ADMIN, ADMIN, VIEWER])
    //     ? {
    //         key: 'setting-notifications',
    //         icon: <Image src={NotificationIcon} width={20} preview={false} />,
    //         className: 'my-0 site-menu-item',
    //         label: `${collapsed ? '' : t('side_bar_3.8')}`,
    //         theme: 'light',
    //         children: [
    //             isValidRoles(role, [SUPER_ADMIN, ADMIN, VIEWER])
    //                 ? {
    //                     key: 'notification',
    //                     className: 'my-0 site-menu-item',
    //                     label: t('side_bar_3.9'),
    //                 }
    //                 : null,
    //             isValidRoles(role, [SUPER_ADMIN])
    //                 ? {
    //                     key: 'category',
    //                     className: 'my-0 site-menu-item',
    //                     label: t('side_bar_3.10'),
    //                 }
    //                 : null,
    //         ],
    //     }
    //     : null,
    // isValidRoles(role, [SUPER_ADMIN])
    //     ?
    //     {
    //         key: 'search',
    //         icon: <Image src={Report} width={20} preview={false} />,
    //         className: 'my-0  site-menu-item',
    //         label: `${collapsed ? '' : t('facility_create_15.24')}`,
    //         theme: 'light',
    //     } : null,
  ];

  const onMenuClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "home":
        navigate("");
        break;
      case "map":
        navigate("/map");
        break;
      case "chargers":
        navigate("/chargers");
        break;
      case "users":
        navigate("/users");
        break;
      case "log":
        navigate("/log");
        break;
      case "chargers-management":
        navigate("/places/chargers-management");
        break;
      case "chargers-history":
        navigate("/chargers-history");
        break;
      case "one-time-payment":
        navigate("/one-time-payment");
        break;
      case "hold-bookings":
        navigate("/hold-bookings");
        break;
      case "schedule-bookings":
        navigate("/schedule-bookings");
        break;
      case "change-password":
        navigate("/change-password");
        break;
      case "facility-type":
        navigate("facility-type");
        break;
      case "charger-maker":
        navigate("charger-maker");
        break;
      case "charger-model":
        navigate("charger-model");
        break;
      case "charger-type":
        navigate("charger-type");
        break;
      case "charger-protocol":
        navigate("charger-protocol");
        break;
      case "places":
        navigate("/places");
        break;
      case "accounts":
        navigate("/accounts");
        break;
      case "notification":
        navigate("/notification");
        break;
      case "category":
        navigate("/category");
        break;
      case "coupons":
        navigate("/coupons");
        break;
      case "coupons-history":
        navigate("/coupons/history");
        break;
      case "white-list":
        navigate("white-list");
        break;
      case "monthly-fees":
        navigate("/monthly-fees");
        break;
      case "search":
        navigate("/search");
        break;
      case "auth-card-id":
        navigate("/auth-card-id");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onCloseCollapsed?.();
  }, [onCloseCollapsed, pathname]);

  return (
    <Sider
      breakpoint={breakpoint}
      collapsedWidth={collapsedWidth}
      onBreakpoint={onBreakpoint}
      onCollapse={onCollapse}
      collapsed={collapsed}
      width={SIDEBAR_WIDTH}
      className="sidebar-collapsed-toggle position-fixed top-0 bottom-0 start-0 site-sidebar ant-menu-light"
      style={{ transition: "0.4s" }}
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
    >
      {/*<Header className='site-sidebar-header text-nowrap px-4 text-center'>*/}
      {/*    <Image src={Logo} preview={false} onClick={redirectHome} />*/}
      {/*</Header>*/}
      {/* {!isMobile && <Image src={Hamburger} className='menuSidebar' onClick={() => setIsCollapsed(!collapsed)} preview={false} />} */}
      <Menu
        key={pathname}
        defaultSelectedKeys={selectedMenus}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        theme="dark"
        tabIndex={1}
        mode="inline"
        className="position-relative overflow-auto ant-sidebar-nav"
        items={menuItems}
        onClick={onMenuClick}
      />
    </Sider>
  );
};

export default Sidebar;
