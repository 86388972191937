import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Constant } from 'config/constant';
import { getListUser, getUserStatistic, getUserStatus, getStatisticUsers, getChartDataUsers, getAllUserList } from 'api/user';
import { getErrorMessage } from 'api';
import { logout } from './authSlice';

export type ListUserState = {
    listUser: {
        error: boolean;
        loading: boolean;
        success: boolean;
        users: otPaymentData[];
        pagination: Pagination;
        status: number;
    };
    statusUser: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: UserStatus[];
    };
    userStatistic: {
        error: boolean;
        success: boolean;
        loading: boolean;
        message: string;
        data: UserStatistic;
    };

    chartData: {
        success: boolean;
        message: string;
        data: ChartData[];
        loading: boolean;
        error: boolean;
    };
    userStatusStatistic: {
        success: boolean;
        message: string;
        data: StatisticUsersData[];
        loading: boolean;
        error: boolean;
    };

};


export type StatisticUsersRequest = {
    start_time?: string;
    end_time?: string;
};

export type ChartDataUsersRequest = {
    start_time?: string;
    end_time?: string;
};

export type  StatisticUsersData = {
    no_of_user: number;
    this_month_user_no: number;
    previous_month_user_no: number;
    active_user_no: number;
}

export type ChartData = {
    date: string;
    no_of_user: number;
}


type UserStatistic = {
    total: number;
    logged_user: number;
    active_user: number;
    new_user: number;
};

type UserStatus = {
    id: number;
    name: string;
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ChargerData = {
    // code: string;
    // place_id: number;
    // place_name: string;
    // name: string;
    charger_uuid: string;
};
export type UserData = {
    uuid: string;
    name: string;
    status: number;
    my_chargers: ChargerData[];
    charger_uuid : string;
    email : string;
    facility_name : string;
    phone_number: string;
};

export type otPaymentData = {
    uuid: string;
    elepay_codeUrl: string;

};

export type ListUserRequest = {
    facility_uuid?: string[];
    user_name?: string;
    phone?: string | number;
    email?: string;
    status_user_id?: string[];
    user_code?: string;
    facility_name?: string;
    page?: number;
};

export const fetchListUser = createAsyncThunk('admin/user', async (params: ListUserRequest, { dispatch, rejectWithValue }) => {
    try {
        const response = await getListUser(params);
        const {
            data = [],
            total = 0,
            perPage = Constant.PAGE_SIZE,
            currentPage = Constant.DEFAULT_PAGE,
            lastPage = Constant.DEFAULT_PAGE,
            success,
        } = response.data;

        if (success) {
            dispatch(setListUser({ users: data, pagination: { total, perPage, currentPage, lastPage } }));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListUser(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});

export const fetchAllUserList = createAsyncThunk('mobile-user/get-all-user', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getAllUserList();
        const {
            data = [],
            success,
        } = response.data;

        if (success) {
            dispatch(setListUser({ users: data }));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListUser(error));
        return rejectWithValue(getErrorMessage(error));
    }
    return false;
});




export const fetchUserStatus = createAsyncThunk('admin/user/status', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getUserStatus();
        const { data, success } = response.data;
        if (success) {
            dispatch(setStatus(data));
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchUserStatistic = createAsyncThunk('admin/user/statistic', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getUserStatistic();
        const { data } = response.data;
        dispatch(setUserStatistic(data));
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        return rejectWithValue(getErrorMessage(error));
    }
});


export const fetchDataChart = createAsyncThunk(
    'users/data-chart',
    async (params: ChartDataUsersRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getChartDataUsers(params);
            dispatch(setChartData(response.data));
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchUserStatusStatistic = createAsyncThunk(
    'users/status/statistic',
    async (params: StatisticUsersRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getStatisticUsers(params);
            dispatch(setUserStatusStatistic(response.data));
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(getErrorMessage(error));
        }
    }
);


export const listUserSlice = createSlice({
    name: 'listUser',
    initialState: {
        listUser: {
            error: false,
            loading: false,
            success: false,
            status: Constant.DEFAULT_STATUS,
            users: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
        },
        statusUser: {
            error: false,
            loading: false,
            success: false,
            message: '',
            data: [],
        },
        userStatistic: {
            error: false,
            loading: false,
            success: false,
            message: '',
            data: {} as UserStatistic,
        },

        chartData: {
            success: false,
            message: '',
            data: [] as ChartData[],
            loading: false,
            error: false,
        },
        userStatusStatistic: {
            success: false,
            message: '',
            data: [] as StatisticUsersData[],
            loading: false,
            error: false,
        },

    } as ListUserState,
    reducers: {
        setListUser: (state: ListUserState, { payload }) => {
            const { users, pagination } = payload;
            state.listUser.users = users;
            state.listUser.pagination = pagination;
            state.listUser.status = payload?.response?.status;
        },
        setStatus: (state: ListUserState, { payload }) => {
            state.statusUser.data = payload;
        },
        setUserStatistic: (state: ListUserState, { payload }) => {
            state.userStatistic.data = payload;
        },

        setChartData: (state: ListUserState, { payload }) => {
            state.chartData.data = payload?.data;
            state.chartData.success = payload?.success;
            state.chartData.message = payload?.message;
        },
        setUserStatusStatistic: (state: ListUserState, { payload }) => {
            state.userStatusStatistic.data = payload?.data;
            state.userStatusStatistic.success = payload?.success;
            state.userStatusStatistic.message = payload?.message;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListUser.pending, (state: ListUserState) => {
                state.listUser.loading = true;
            })
            .addCase(fetchListUser.rejected, (state: ListUserState) => {
                state.listUser.loading = false;
                state.listUser.success = false;
                state.listUser.error = true;
            })
            .addCase(fetchListUser.fulfilled, (state: ListUserState) => {
                state.listUser.loading = false;
                state.listUser.success = true;
                state.listUser.error = false;
            })
            .addCase(fetchUserStatus.pending, (state: ListUserState) => {
                state.statusUser.loading = true;
            })
            .addCase(fetchUserStatus.rejected, (state: ListUserState) => {
                state.statusUser.loading = false;
                state.statusUser.success = false;
                state.statusUser.error = true;
            })
            .addCase(fetchUserStatus.fulfilled, (state: ListUserState) => {
                state.statusUser.loading = false;
                state.statusUser.success = true;
                state.statusUser.error = false;
            })

            .addCase(fetchDataChart.pending, (state: ListUserState) => {
                state.chartData.loading = true;
            })
            .addCase(fetchDataChart.rejected, (state: ListUserState, { payload }) => {
                state.chartData.loading = false;
                state.chartData.success = false;
                state.chartData.error = true;
            })
            .addCase(fetchDataChart.fulfilled, (state: ListUserState, action) => {
                state.chartData.loading = false;
                state.chartData.success = true;
                state.chartData.error = false;
            })
            .addCase(fetchUserStatusStatistic.pending, (state: ListUserState) => {
                state.userStatusStatistic.loading = true;
            })
            .addCase(fetchUserStatusStatistic.rejected, (state: ListUserState, { payload }) => {
                state.userStatusStatistic.loading = false;
                state.userStatusStatistic.success = false;
                state.userStatusStatistic.error = true;
            })
            .addCase(fetchUserStatusStatistic.fulfilled, (state: ListUserState, action) => {
                state.userStatusStatistic.loading = false;
                state.userStatusStatistic.success = true;
                state.userStatusStatistic.error = false;
            })

            .addCase(fetchUserStatistic.pending, (state: ListUserState) => {
                state.userStatistic.loading = true;
            })
            .addCase(fetchUserStatistic.rejected, (state: ListUserState) => {
                state.userStatistic.loading = false;
                state.userStatistic.success = false;
                state.userStatistic.error = true;
            })
            .addCase(fetchUserStatistic.fulfilled, (state: ListUserState) => {
                state.userStatistic.loading = false;
                state.userStatistic.success = true;
                state.userStatistic.error = false;
            });
    },
});

export const listUserSelector = (state: RootState) => state.listUsers;
export const { 
    setListUser, 
    setStatus, 
    setUserStatistic,
    setChartData,
    setUserStatusStatistic
 } = listUserSlice.actions;
