import { getAxiosClientWithJWTToken } from "api";
import { invoiceData } from "types";

export const getFacility = () => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get("/facility/list-uuid-name");
};

export const createInvoiceRequest = (params: invoiceData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post("/facility/invoice", params)
}