import { getAxiosClient, getAxiosClientWithJWTToken } from "api";
import {LoginRequest} from "redux/slice/loginSlice";

export const login = (params: LoginRequest) => {
  return getAxiosClient().post('auth-login/verify-otp', {"email" : params.username, "otp" : params.password, "lang" : params.lang});
};

export const getUserData = () => {
  return getAxiosClientWithJWTToken().get("auth-profile");
};
