import { getAxiosClientWithJWTToken, getAxiosClientWithToken } from 'api';
import {ListCouponRequest} from "../redux/slice/couponSlice";
import {ListCouponUserRequest} from "redux/slice/couponUser";
export const getListCouponUser = (params: ListCouponRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/admin/coupon-user', { params });
};
export const getDetailCouponUser = (id: string, lang: string) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get(`/coupon/history/details?uuid=${id}&lang=${lang}`);
};
export const exportCSV = (params: ListCouponUserRequest) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/coupon/history/csv', { params });
};
