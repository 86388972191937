import {getAxiosClientWithJWTToken} from 'api';
import {RegisterChargerTypeData} from 'types'

export const createChargerType= (params: RegisterChargerTypeData) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_types/create`, params);
};

export const getListChargerType = (params: {} ) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.get('/charger-type/list', { params });
};

export const UpdateChargerType = (params: { uuid: string, name: string }) => {
    const restClient = getAxiosClientWithJWTToken();
    return restClient.post(`/charger_types/update`, params);
};

export const deleteChargerType = (params: { uuid?: string, lang : string }) => {
    return getAxiosClientWithJWTToken().delete(`/charger_types/delete?uuid=` + params.uuid + `&lang=` + params.lang);
};