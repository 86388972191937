import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Constant } from 'config/constant';
import { RootState } from 'redux/store';
import { getErrorMessage } from 'api';
import { logout } from './authSlice';
import {
    CouponData,
    CreateCouponData,
    CouponHistory
} from 'types';
import {createCoupon, deleteCoupon, getDetailCoupon, getListCoupon, getSearchListCoupon, getHistoryListCoupon, getStatisticCoupon} from "../../api/coupon";

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type ListCoupon = {
    error: boolean;
    loading: boolean;
    success: boolean;
    coupon: CouponData[];
    pagination: Pagination;
    status: string;
};

export type StatisticCoupon = {
    no_of_coupon: number;
    no_of_applied_coupon: number;
    discount_money_applied: number;
    no_of_used_coupon: number;
    discount_money_used: number;
}

export type CouponState = {
    status: number;
    couponData: CouponData[];
    listCoupon: ListCoupon;
    listSearchCoupon: {
        error: boolean;
        loading: boolean;
        success: boolean;
        coupons: CouponData[];
    };
    register: {
        loading: boolean;
        success: boolean;
        error: boolean;
        message: string | CouponData;
    };
    delete: {
        success: boolean;
        error: boolean;
        messages: string;
        loading: boolean;
    };
    detail: {
        success: boolean;
        error: boolean;
        messages: string | CouponData;
        loading: boolean;
        createNotifyAvailable: boolean;
        dataDetail: CouponData;
    };
    couponHistory: {
        error: boolean;
        loading: boolean;
        success: boolean;
        coupons: CouponHistory[];
        pagination: Pagination;
    };
    statisticCoupon: {
        success: boolean;
        message: string;
        data: StatisticCoupon;
        loading: boolean;
        error: boolean;
    };
};

export type ListCouponRequest = {
    page?: number;
    keyword?: string;
    issuer?: string,
    place_id?: string;
    name?: string;
    start_time?: string,
    end_time?: string;
};

export type StatisticCouponRequest = {
    place_id?: string;
    name?: string;
    start_time?: string,
    end_time?: string;
}

export const fetchListCoupon = createAsyncThunk(
    'admin/coupon',
    async (params: ListCouponRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getListCoupon(params);
            const {
                data = [],
                total = 0,
                per_page = Constant.PAGE_SIZE_NEW,
                current_page = Constant.DEFAULT_PAGE,
                last_page = Constant.DEFAULT_PAGE,
                success,
            } = response.data;
            if (success) {
                dispatch(setListCoupon({ coupon: data, pagination: { total, perPage: per_page, currentPage: current_page, lastPage: last_page } }));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setListCoupon(error));
            return rejectWithValue(getErrorMessage(error));
        }
        return rejectWithValue(false);
    }
);

export const fetchListSearchCoupon = createAsyncThunk('admin/coupon/search', async (params: ListCouponRequest, { dispatch }) => {
    try {
        const response = await getSearchListCoupon(params);
        const { data = [], success } = response.data;

        if (success) {
            dispatch(setListSearchCoupon({ coupons: data }));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListSearchCoupon(error));
    }
    return false;
});


export const fetchListHistoryCoupon = createAsyncThunk('coupon/history', async (params: ListCouponRequest, { dispatch }) => {
    try {
        const response = await getHistoryListCoupon(params);
        const { 
             data = [],
             success,
             total = 0,
             per_page: perPage = Constant.PAGE_SIZE,
             current_page: currentPage = Constant.DEFAULT_PAGE,
             last_page: lastPage = Constant.DEFAULT_PAGE,
            } = response.data;

        if (success) {
            dispatch(setListHistoryCoupon({ coupons: data, pagination: { total, perPage, currentPage, lastPage } }));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListHistoryCoupon(error));
    }
    return false;
});

export const createCouponAction = createAsyncThunk(
    'admin/coupon/store',
    async (params: CreateCouponData, { dispatch, rejectWithValue }) => {
        try {
            let response = await createCoupon(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setNewCoupon(data));
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setNewCoupon(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);
export const destroyCoupon = createAsyncThunk(
    'admin/coupon/:id/delete',
    async (params: {lang: string, uuid: string, status: number}, { dispatch, rejectWithValue }) => {
        try {
            const response = await deleteCoupon(params);
            const { success } = response.data;
            if (success) {
                return true;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return rejectWithValue(error);
        }
    }
);

export const getDetailCouponData = createAsyncThunk(
    'details/coupon/:uuid',
    async (params: {uuid: string, lang: string}, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDetailCoupon(params);
            const { success } = response?.data;
            if (success) {
                dispatch(setDetailCoupon(response?.data));
                return success;
            }
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            dispatch(setDetailCoupon(error));
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchStatisticCoupon = createAsyncThunk('coupon/history/statistics', async (params: StatisticCouponRequest, { dispatch }) => {
    try {
        const response = await getStatisticCoupon(params);
        const { success } = response.data;

        if (success) {
            dispatch(setStatisticCoupon(response?.data));
            return true;
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setStatisticCoupon(error));
    }
    return false;
});

export const couponSlice = createSlice({
    name: 'coupon',
    initialState: {
        status: Constant.DEFAULT_STATUS,
        couponData: [],
        listSearchCoupon: {
            error: false,
            loading: false,
            success: false,
            coupons: []
        },
        register: {
            loading: false,
            success: false,
            error: false,
            message: '',
        },
        delete: {
            success: false,
            error: false,
            messages: '',
            loading: false,
        },
        listCoupon: {
            error: false,
            loading: false,
            success: false,
            coupon: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            },
            status: '',
        },
        detail: {
            success: false,
            error: false,
            messages: '',
            loading: false,
            createNotifyAvailable: false,
            dataDetail: {} as CouponData,
        },
        couponHistory: {
            error: false,
            loading: false,
            success: false,
            coupons: [],
            pagination: {
                total: 0,
                perPage: Constant.PAGE_SIZE_NEW,
                currentPage: Constant.DEFAULT_PAGE,
                lastPage: Constant.DEFAULT_PAGE,
            }
        },
        statisticCoupon: {
            success: false,
            message: '',
            data: {} as StatisticCoupon,
            loading: false,
            error: false,
        }
    } as CouponState,
    reducers: {
        setListSearchCoupon: (state, { payload }) => {
            const { coupons } = payload;
            state.listSearchCoupon.coupons = coupons;
            state.status = payload?.response?.status;
        },
        resetRegisterState: (state: CouponState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.register = {
                success: false,
                loading: false,
                message: '',
                error: false,
            };
        },
        setNewCoupon: (state, action) => {
            state.couponData = action?.payload ?? [];
            state.status = action?.payload?.response?.status;
        },
        setListCoupon: (state: CouponState, { payload }) => {
            const { coupon, pagination } = payload;
            state.listCoupon.coupon = coupon;
            state.listCoupon.pagination = pagination;
        },
        setDetailCoupon: (state: CouponState, { payload }) => {
            state.detail.dataDetail = payload?.data;
            state.detail.createNotifyAvailable = payload?.createNotifyAvailable;
            state.detail.success = payload?.success;
            state.detail.messages = payload?.message;
            state.status = payload?.response?.status;
        },
        setListHistoryCoupon: (state: CouponState, { payload }) => {
            const { pagination } = payload;
            state.couponHistory.coupons = payload.coupons;
            state.status = payload?.response?.status;
            state.couponHistory.pagination = pagination;
        },
        resetDeleteState: (state: CouponState) => {
            state.status = Constant.DEFAULT_STATUS;
            state.delete = {
                loading: false,
                success: false,
                error: false,
                messages: '',
            };
        },
        setStatisticCoupon: (state: CouponState, { payload }) => {
            state.statisticCoupon.data = payload?.data;
            state.statisticCoupon.success = payload?.success;
            state.statisticCoupon.message = payload?.message;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListCoupon.pending, (state: CouponState) => {
                state.listCoupon.loading = true;
            })
            .addCase(fetchListCoupon.rejected, (state: CouponState) => {
                state.listCoupon.loading = false;
                state.listCoupon.success = false;
                state.listCoupon.error = true;
            })
            .addCase(fetchListCoupon.fulfilled, (state: CouponState) => {
                state.listCoupon.loading = false;
                state.listCoupon.success = true;
                state.listCoupon.error = false;
            })
            .addCase(createCouponAction.pending, (state: CouponState) => {
                state.register.loading = true;
            })
            .addCase(createCouponAction.rejected, (state: CouponState, { payload }) => {
                state.register.loading = false;
                state.register.success = false;
                state.register.error = true;
                state.register.message = payload as string | CouponData;
            })
            .addCase(createCouponAction.fulfilled, (state: CouponState) => {
                state.register.loading = false;
                state.register.success = true;
                state.register.error = false;
            })
            .addCase(fetchListHistoryCoupon.pending, (state: CouponState) => {
                state.couponHistory.loading = true;
            })
            .addCase(fetchListHistoryCoupon.rejected, (state: CouponState) => {
                state.couponHistory.loading = false;
                state.couponHistory.success = false;
                state.couponHistory.error = true;
            })
            .addCase(fetchListHistoryCoupon.fulfilled, (state: CouponState) => {
                state.couponHistory.loading = false;
                state.couponHistory.success = true;
                state.couponHistory.error = false;
            })
            .addCase(destroyCoupon.pending, (state: CouponState) => {
                state.delete.loading = true;
            })
            .addCase(destroyCoupon.rejected, (state: CouponState) => {
                state.delete.loading = false;
                state.delete.success = false;
                state.delete.error = true;
            })
            .addCase(destroyCoupon.fulfilled, (state: CouponState) => {
                state.delete.loading = false;
                state.delete.success = true;
                state.delete.error = false;
            })
            .addCase(getDetailCouponData.pending, (state: CouponState) => {
                state.detail.loading = true;
            })
            .addCase(getDetailCouponData.rejected, (state: CouponState, { payload }) => {
                state.detail.loading = false;
                state.detail.success = false;
                state.detail.error = true;
                state.detail.messages = payload as string;
            })
            .addCase(getDetailCouponData.fulfilled, (state: CouponState) => {
                state.detail.loading = false;
                state.detail.success = true;
                state.detail.error = false;
            })
            .addCase(fetchStatisticCoupon.pending, (state: CouponState) => {
                state.statisticCoupon.loading = true;
            })
            .addCase(fetchStatisticCoupon.rejected, (state: CouponState, { payload }) => {
                state.statisticCoupon.loading = false;
                state.statisticCoupon.success = false;
                state.statisticCoupon.error = true;
            })
            .addCase(fetchStatisticCoupon.fulfilled, (state: CouponState, action) => {
                state.statisticCoupon.loading = false;
                state.statisticCoupon.success = true;
                state.statisticCoupon.error = false;
            });
    },
});

export const {
    setStatisticCoupon,
    resetRegisterState,
    setNewCoupon,
    setListCoupon,
    setDetailCoupon,
    setListSearchCoupon,
    setListHistoryCoupon,
    resetDeleteState,
} = couponSlice.actions;
export const couponSelector = (state: RootState) => state.coupon;