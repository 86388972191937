import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { logout } from './authSlice';
import { getCitybyState, getListPlace, getState } from 'api/place';
import { Constant } from 'config/constant';

export type ListPlaceState = {
    error: boolean;
    loading: boolean;
    success: boolean;
    places: PlaceData[];
    prefecture: {
        success: boolean;
        message: string;
        data: Prefecture[];
        loading: boolean;
        error: boolean;
        status: number;
    };
    cityByPrefecture: {
        success: boolean;
        message: string;
        data: City[];
        loading: boolean;
        error: boolean;
    };
    pagination: Pagination;
    status: number;
};
export type PlaceState = {
    error: boolean;
    loading: boolean;
    success: boolean;
    places: PlaceData[];
};

type Pagination = {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
};

export type Prefecture = {
    name: string;
};

export type City = {
    name: string;
};

export type PlaceData = {
    id: number;
    code: string;
    name: string;
    address: string;
    chargers_uuid: string[];
    building_name: string;
    prefecture: Prefecture;
    cityByPrefecture: {
        success: boolean;
        message: string;
        data: City[];
        loading: boolean;
        error: boolean;
    };
    city: string;
    place_type_id: number;
    facility_type_name: string;
    facility_type_name_en: string;
    status: number;
    uuid: string;
    total_chargers: number;
};

export type PlaceType = {
    id: number;
    name: string;
};

export type ListPlaceRequest = {
    page?: number;
    keyword?: string;
    state?: string;
    city?: string;
    place_type_id?: string;
    uuid?: string ;
    address?: string;
    name?: string;
};

export type idPlace = {
    id?: number;
};

export const listPlaceSlice = createSlice({
    name: 'listPlace',
    initialState: {
        error: false,
        loading: false,
        success: false,
        places: [],
        prefecture: {
            success: false,
            message: '',
            data: [] as Prefecture[],
            loading: false,
            error: false,
            status: 0
        },
        cityByPrefecture: {
            success: false,
            message: '',
            data: [] as City[],
            loading: false,
            error: false,
        },
        pagination: {
            total: 0,
            perPage: Constant.PAGE_SIZE,
            currentPage: Constant.DEFAULT_PAGE,
            lastPage: Constant.DEFAULT_PAGE,
        },
        status: Constant.DEFAULT_STATUS,
    } as ListPlaceState,
    reducers: {
        setStatusPlace: (state: ListPlaceState) => {
            state.status = Constant.DEFAULT_STATUS;
        },
        setListPlace: (state: ListPlaceState, { payload }) => {
            const { places, pagination } = payload;
            state.places = places;
            state.pagination = pagination;
            state.status = payload?.response?.status;
        },
        setPrefecture: (state: ListPlaceState, { payload }) => {
            state.prefecture.data = payload?.data;
            state.prefecture.success = payload?.success;
            state.prefecture.message = payload?.message;
            state.prefecture.status = payload?.response?.status;
        },
        setCityByPrefecture: (state: ListPlaceState, { payload }) => {
            state.cityByPrefecture.data = payload?.data;
            state.cityByPrefecture.success = payload?.success;
            state.cityByPrefecture.message = payload?.message;
        },
        resetListPlace: (state: ListPlaceState, {payload}) => {
            state.status = Constant.DEFAULT_STATUS;
            state.places = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListPlace.pending, (state: ListPlaceState) => {
                state.loading = true;
            })
            .addCase(fetchListPlace.rejected, (state: ListPlaceState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            })
            .addCase(fetchListPlace.fulfilled, (state: ListPlaceState) => {
                state.loading = false;
                state.success = true;
                state.error = false;
            });
    },
});

export const fetchListPlace = createAsyncThunk('/facility/filter', async (params: ListPlaceRequest, { dispatch }) => {
    try {
        const response = await getListPlace(params);
        const {
            data = [],
            total = 0,
            per_page: perPage = Constant.PAGE_SIZE,
            current_page: currentPage = Constant.DEFAULT_PAGE,
            last_page: lastPage = Constant.DEFAULT_PAGE,
            success,
        } = response.data;
        if (success) {
            dispatch(setListPlace({ places: data, pagination: { total, perPage, currentPage, lastPage } }));
            return true;
        }
        else{
            dispatch(resetListPlace({places: []}))
        }
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setListPlace(error));
    }
    return false;
});

export const getStateData = createAsyncThunk('state', async (_, { dispatch, rejectWithValue }) => {
    try {
        let response = await getState();
        const { success } = response.data;
        if (success) {
            dispatch(setPrefecture(response.data));
            return true;
        }
        return rejectWithValue(false);
    } catch (error: any) {
        if (error?.response?.data?.message === "TOKEN_EXPIRED") {
            dispatch(logout());
        }
        dispatch(setPrefecture(error));
    }
});

export const getListCityByPrefectureData = createAsyncThunk(
    'state/city',
    async (params: {}, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCitybyState(params);
            const { success } = response.data;
            if (success) {
                dispatch(setCityByPrefecture(response.data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            if (error?.response?.data?.message === "TOKEN_EXPIRED") {
                dispatch(logout());
            }
            return setCityByPrefecture(error);
        }
    }
);


export const { setListPlace, setStatusPlace, setPrefecture, setCityByPrefecture, resetListPlace } = listPlaceSlice.actions;
export const listPlaceSelector = (state: RootState) => state.listPlaces;

