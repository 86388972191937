import React, { CSSProperties, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { ADMIN, SUPER_ADMIN } from 'config/constant';
import { Dropdown, Layout, Menu, Button, Avatar, Image, Tooltip } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { isValidRoles, useIsMobile, useScrollToTop} from 'lib/utils';
import Sidebar from 'components/layouts/Sidebar';
import { SIDEBAR_WIDTH } from 'config/constant';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { logout, selectAuth } from 'redux/slice/authSlice';
import type { MenuProps } from 'antd';
import Logo from 'assets/images/terra-charge-logo-sidebar.png';
import Languageicon from 'assets/images/language_white.png';
// import { US, JP } from 'country-flag-icons/react/3x2';
import { useTranslation } from "react-i18next";
// @ts-ignore
//import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const { Header, Content } = Layout;

type Props = {
    containerClass?: string;
    contentStyle?: CSSProperties;
};

/**
 * Creates Base Layout
 * @param { Props } props
 * @return html
 * */
const BaseLayout = (props: Props) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const { containerClass = '', contentStyle } = props;
    const onCloseCollapsed = useCallback(() => isMobile && setIsCollapsed(true), [isMobile]);
    const dispatch = useAppDispatch();
    const { token, name, email, role } = useAppSelector(selectAuth);
    const location = useLocation();
    useScrollToTop();

    const { t, i18n } = useTranslation(['home']);

    const de_lan = i18n.resolvedLanguage;

    const onLogout = () => {
        dispatch(logout());
    };

    const onGotoProfile = () => navigate('/detail-profile');

    const accountActionItems: MenuProps['items'] = [
        {
            icon: <LogoutOutlined className='fs-6 me-2' />,
            label: t('side_bar_3.14'),
            key: 'logout',
        },
    ];

    const onMenuClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'profile':
                onGotoProfile();
                break;
            case 'logout':
                onLogout();
                break;
            default:
                break;
        }
    };

    const onLangMenuClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'en':
                i18n.changeLanguage(key);
                window.sessionStorage.setItem('lang', 'en');
                break;
            case 'jp':
                i18n.changeLanguage(key);
                window.sessionStorage.setItem('lang', 'jp');
                break;
            default:
                i18n.changeLanguage('jp');
                window.sessionStorage.setItem("lang", "jp");
                break;
        }
        
    };

    const redirectHome = () => {
        navigate('/');
    };

    useEffect(() => {
        if (!token) {
            navigate('/login', { state: { from: location }, replace: true });
        }
    }, [token, location, navigate]);

    

    const languageOptions: MenuProps['items'] = [
        {
            // icon: <US title={`${t('language_select.en')}`} width='25px' />,
            label: `${t('language_select.en')}`,
            key: 'en',
        },
        {
            // icon: <JP title={`${t('language_select.jp')}`} width='25px' />,
            label: `${t('language_select.jp')}`,
            key: 'jp',
        },
    ];
      

    return (
        <Layout hasSider className='base-layout-container h-100'>
            {isMobile ? (
                <Sidebar
                    key={+isMobile}
                    breakpoint='md'
                    collapsedWidth='0'
                    collapsed={isCollapsed}
                    onCollapse={(collapsed) => setIsCollapsed(collapsed)}
                    onCloseCollapsed={onCloseCollapsed}
                    setIsCollapsed={setIsCollapsed}
                />
            ) : !isCollapsed ? (
                <Sidebar
                    key={+isMobile}
                    breakpoint='md'
                    collapsedWidth='0'
                    collapsed={isCollapsed}
                    onCollapse={(collapsed) => setIsCollapsed(collapsed)}
                    onCloseCollapsed={onCloseCollapsed}
                    setIsCollapsed={setIsCollapsed}
                />
            ) : (
                // <Image className='hamburgerMenu' onClick={() => setIsCollapsed(!isCollapsed)} src={Hamburger} preview={false} />
                <Sidebar
                    key={+isMobile}
                    breakpoint='md'
                    collapsedWidth='100px'
                    collapsed={isCollapsed}
                    onCollapse={(collapsed) => setIsCollapsed(!collapsed)}
                    onCloseCollapsed={onCloseCollapsed}
                    setIsCollapsed={setIsCollapsed}
                />
            )}

            <Layout
                className={!isCollapsed && !isMobile ? 'section-collapsed site-layout' : 'site-layout'}
                // className='site-layout'
                style={{ marginLeft: isMobile ? 0 : !isCollapsed ? SIDEBAR_WIDTH : 0 }}
                onClick={onCloseCollapsed}
            >
                <Header
                    className={
                        !isCollapsed && !isMobile
                            ? 'data-collapsed site-layout-sub-header-background site-default-header position-sticky top-0 '
                            : 'site-layout-sub-header-background site-default-header position-sticky top-0 '
                    }
                >
                    <Image src={Logo} width='217px' preview={false} onClick={redirectHome} />
                    <div className='float-end'>
                        <Dropdown
                            arrow
                            overlay={<Menu onClick={onMenuClick} className='rounded-3 px-1' items={accountActionItems} />}
                            placement='bottomRight'
                            trigger={['click']}
                        >
                            
                            <Button type='link' tabIndex={3} className='text-white btn-toggle-account-menu onTab'>
                                <Avatar icon={<UserOutlined />} size='small' className={'mx-2'} />
                                {name}  
                            </Button>
                        </Dropdown>
                    </div>
                    <div className='float-end'>
                            <Button type='link' className='text-white btn-toggle-account-menu'>
                            <Tooltip title={email}>
                                <span>                            
                                    {isValidRoles(role, [SUPER_ADMIN]) ? t('home_4.サービス管理者') : (isValidRoles(role, [ADMIN]) ? t('home_4.施設管理者') : t('home_4.施設閲覧者')) }
                                </span>
                            </Tooltip>

                            </Button>
                    </div>
                    <div className='float-end'>
                        <Dropdown
                            arrow
                            overlay={<Menu onClick={onLangMenuClick} className='rounded-3 px-1' selectable={true} defaultSelectedKeys={[de_lan]} items={languageOptions} />}
                            placement='bottomRight'
                            trigger={['click']}
                        >
                            <Button type='link' tabIndex={2}  className='text-white btn-toggle-account-menu onTab'>
                                <Avatar src={Languageicon} size='small' className={'mx-2'} />
                                {`${t('language_select.btn')}`} 
                            </Button>
                        </Dropdown>
                    </div>
                </Header>
                <Content
                    className={
                        isCollapsed
                            ? classNames('main-container-collapse ml-100', containerClass)
                            : classNames('main-container background-white content-collapsed', containerClass)
                    }
                    style={contentStyle}
                >
                    <div className='site-layout-background'>
                        <Outlet />
                    </div>
                    
                </Content>
                
            </Layout>
            {/* <TawkMessengerReact
                    propertyId="6407dfdb4247f20fefe495b4"
                    widgetId="1gqvc4sba"
                /> */}
        </Layout>
    );
};

export default BaseLayout;
